import React, { useEffect, forwardRef } from "react";
import { pdfjs } from "react-pdf";
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.js';
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
export default forwardRef((props, ref) => {
    const [pdf, setPdf] = React.useState("");
    const [width, setWidth] = React.useState(0);
    const [images, setImages] = React.useState([]);
    const [height, setHeight] = React.useState(0);
    const [pdfRendering, setPdfRendering] = React.useState("");
    const [pageRendering, setPageRendering] = React.useState("");

    useEffect(() => {
    }, []);

    const showPdf = async () => {
        try {
            setPdfRendering(true);
            const uri = URL.createObjectURL(props.dataBlob);
            const _PDF_DOC = await pdfjs.getDocument({ url: uri }).promise;
            console.log(_PDF_DOC);
            setPdf(_PDF_DOC);
            setPdfRendering(false);
        } catch (error) {
            alert(error.message);
        }
    }

    const renderPage = async () => {
        setPageRendering(true);
        const imagesList = [];
        const canvas = document.createElement("canvas");
        canvas.setAttribute("className", "canv");

        for (let i = 1; i <= pdf.numPages; i++) {
            var page = await pdf.getPage(i);
            page.cleanupAfterRender = false;
            var viewport = page.getViewport({ scale: 1 });
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            var render_context = {
                canvasContext: canvas.getContext("2d"),
                viewport: viewport
            };
            setWidth(viewport.width);
            setHeight(viewport.height);
            await page.render(render_context).promise;
            let img = canvas.toDataURL("image/png", 1);
            imagesList.push(img);
        }
        setImages(imagesList);
        setPageRendering(false);
    }

    useEffect(() => {
        pdf && renderPage();
    }, [pdf]);

    const styles = {
        wrapper: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "5px"
        },
        imageWrapper: {
            border: "1px solid rgba(0,0,0,0.15)",
            borderRadius: "3px",
            boxShadow: "0 2px 5px 0 rgba(0,0,0,0.25)",
            padding: "0"
        }
    };

    useEffect(() => {
        if (props.dataBlob) showPdf();
    }, [props.dataBlob])

    return (
        <div className="pdf">
            <div id="pdf-main-container">
                <div id="pdf-loader" hidden={!pdfRendering}>
                    Đang tải...
                </div>
                <div id="pdf-contents">
                    <div id="image-convas-row">
                        <div style={styles.wrapper}>
                            {images.map((image, idx) => (
                                <div key={idx} style={styles.imageWrapper}>
                                    <img
                                        id="image-generated"
                                        src={image}
                                        alt="pdfImage"
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            margin: "0",
                                            border: "none"
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div id="page-loader" hidden={!pageRendering}>
                        Đang tải...
                    </div>
                </div>
            </div>
        </div>
    );
})