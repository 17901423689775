import * as Actions from '../libs/actions';
import * as ApiCaller from '../libs/httpRequests';

export function PrintDetailResultTest(sid, maql, ngay) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`Print/PrintDetailResultTest?sid=${sid}&maql=${maql}&ngay=${ngay}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            Actions.openMessageDialog("Error", err.toString());
            return reject(err)
        })
    });
};