import { Button, Tooltip } from 'antd';
import React, { useCallback, useRef, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { connect } from 'react-redux';
import * as Actions from '../../libs/actions';
import Notify, { NotifyStatus } from '../../components/notify';
import * as CoporatorService from "../../services/coporatorService";
import moment from 'moment';
import Search from "../../components/mita-result/search";
import Summary from '../../components/mita-result/summary';
import MitaTable from "../../components/mita-result/table";
import history from '../../stores/history';

const CoporatorResult = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
    }));

    useEffect(() => {
        onSearch();
    }, []);

    const [, updateState] = React.useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    const _notify = useRef();
    const _summary = useRef();
    const [totalRecords, setTotalRecords] = useState(0);
    const [skipCount, setSkipCount] = useState(0);
    const [maxResultCount, setMaxResultCount] = useState(10);
    const [filterModel, setFilterModel] = useState({
        skipCount: 0,
        maxResultCount: 10,
        keyword: "",
        dateFrom: sessionStorage.getItem("dateFrom") ? moment(sessionStorage.getItem("dateFrom")) : moment(),
        dateTo: sessionStorage.getItem("dateTo") ? moment(sessionStorage.getItem("dateFrom")) : moment(),
    })
    const [dataList, setDataList] = useState([]);

    const onSearch = (filter = filterModel) => {
        Actions.setLoading(true);
        if (filter) {
            filter.skipCount = filter.skipCount ?? skipCount;
            filter.maxResultCount = filter.maxResultCount ?? maxResultCount;
        }
        _summary && _summary.current.onSearch(filter);
        CoporatorService.CoporatorListResult(filter).then(result => {
            if (result.isSuccess) {
                let data = result.data.items;
                if (filter.skipCount === 0) {
                    setDataList(data);
                }
                else {
                    setDataList([...dataList, ...data]);
                }
                setTotalRecords(result.data.totalCount);
                _notify && _notify.current && _notify.current.Close();
            } else {
                _notify && _notify.current && _notify.current.Show(result.error.messageText, NotifyStatus.Error);
            }
        }).catch(err => {
            _notify && _notify.current && _notify.current.Show(err.error.messageText, NotifyStatus.Error);
        }).finally(() => {
            Actions.setLoading(false);
        })
    }

    const showNotification = (message, status = NotifyStatus.Warning) => {
        _notify && _notify.current && _notify.current.Show(message, status);
    }

    const columns = [
        {
            title: 'Họ tên',
            dataIndex: 'patientName',
            key: 'patientName',
            width: 200,
            align: 'center',
        },
        {
            title: 'Ngày sinh',
            dataIndex: 'dateOfBirth',
            key: 'dateOfBirth',
            render: (dob, source) => (source.onlyYear ? moment(dob).format("YYYY") : moment(dob).format("DD/MM/YYYY")),
            width: 100,
            align: 'center',
        },
        {
            title: 'Giới tính',
            dataIndex: 'gender',
            key: 'gender',
            width: 100,
            align: 'center',
        },
        {
            title: 'Số điện thoại',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
            width: 100,
            align: 'center',
        }
    ]

    const onRowClick = useCallback((data) => history.push(`/chi-tiet`, { data }), [history]);

    return (
        <div className='mita-container h-100 d-flex flex-column'>
            <Notify className="mt-2" ref={_notify} />
            <Search
                onSearch={(filterModel) => { onSearch(filterModel); setFilterModel(filterModel); setSkipCount(0); }}
                showNotification={(message, status) => showNotification(message, status)} />
            <div className='mrt-1'>
                <MitaTable columns={columns} data={dataList} groupOn="dateIn"
                    onRowClick={(rowData) => { onRowClick(rowData) }} />
            </div>
            <div className="mrt-1 w-100 d-flex justify-content-center">
                <Tooltip overlayStyle={{ minWidth: "fit-content" }} placement='top' title={<div className="mita-text">Xem thêm {totalRecords - dataList.length > maxResultCount ? maxResultCount : totalRecords - dataList.length} của {totalRecords - dataList.length} trên tổng số {totalRecords}</div>}>
                    <Button className="mita-btn-round-sub"
                        hidden={totalRecords <= (dataList ?? []).length}
                        onClick={() => {
                            setSkipCount(skipCount + maxResultCount);
                            onSearch({
                                ...filterModel,
                                skipCount: skipCount + maxResultCount,
                            });
                        }}
                    >Xem thêm</Button>
                </Tooltip>
            </div>
            <Summary
                className="pdb-1"
                ref={_summary}
                showNotification={(message, status) => showNotification(message, status)} />
        </div>
    );
})

const mapStateToProps = (state) => {
    return ({
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(CoporatorResult);