import * as ApiCaller from '../libs/httpRequests';

const prefix = 'Pasc';

export function DanhSachLoaiKyThuatXetNghiem(loai)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachLoaiKyThuatXetNghiem?loai=${loai}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function TemplateCDHA(loai)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/TemplateCDHA/${loai}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function DanhSachThuocTinh(loai,config=false)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachThuocTinh/${loai}?config=${config}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function LoaiVPCDHA(loai)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/LoaiVPCDHA/${loai}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function ThongTinDaKhamThai(idchidinh,loai,makt,ngay, maql , mabn )  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/ThongTinDaKhamThai?idchidinh=${idchidinh}&loai=${loai}&makt=${makt}&ngay=${ngay}&maql=${maql}&mabn=${mabn} `).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function DanhSachThucHien(loai,tungay,denngay,key,dathuchien)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/danhsachthuchien?loai=${loai}&tungay=${tungay}&denngay=${denngay}&key=${key}&dathuchien=${dathuchien}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function LuuSieuAmThai(data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/LuuSieuAmThai`,data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function downloadVideo(maql,idcd, stt) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/downloadVideo?maql=${maql}&idcd=${idcd}&stt=${stt}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
           return reject(err)
        })
    });
};

export function saveVideo(data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostFormData(`${prefix}/saveVideo`,data,true,true).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function getVideo(maql,idChiDinh)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/getVideo?maql=${maql}&idchidinh=${idChiDinh}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function getdanhsachvideo(fromdate,todate,key)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/danhsachvideo?fromdate=${fromdate}&todate=${todate}&key=${key}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function deleteVideo(maql,idcd, stt) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/deleteVideo?maql=${maql}&idcd=${idcd}&stt=${stt}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
           return reject(err)
        })
    });
};
export function saveImage(data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostFormData(`${prefix}/saveImage`,data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function loadImage(maql,idcd, filename) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/loadImage?maql=${maql}&idcd=${idcd}&filename=${filename}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
           return reject(err)
        })
    });
};

export function ThemThuocTinh(data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/ThemThuocTinh`,data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function SuaThuocTinh(data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/SuaThuocTinh`,data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function DeleteCDHA(id)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/DeleteCDHA/${id}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function DeleteCDHAType(id)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/DeleteCDHAType/${id}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function DeleteCDHAKT(id)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/DeleteCDHAKT/${id}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function InsertUpdateCDHA(data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/InsertUpdateCDHA`,data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};


export function InsertUpdateCDHAType(data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/InsertUpdateCDHAType`,data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function InsertUpdateCDHAKT(data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/InsertUpdateCDHAKT`,data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function HideAutoRecordCDHAKT(idvp,auto)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/HideAutoRecordCDHAKT?idvp=${idvp}&auto=${auto}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
