import * as ApiCaller from '../libs/httpRequests';

const prefix = 'users';
export function changePass(newPassword)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`thirdparty/changepass`, newPassword).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};