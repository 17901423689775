import { DatePicker, notification, Modal, Divider, Button, Tooltip } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import React, { Component } from 'react';
import * as PatientService from "../../../services/patients";
import * as Actions from '../../../libs/actions';
import ChangePassword from "../../../components/changePassword";
import * as PrintService from '../../../services/printService';
import DetailsHistory from '../../../components/history/historydetails';

export default class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            totalRecords: 0,
            currentPage: 1,
            currentSize: 10,
            lstTests: [],
            lstBN: [],
            selected: "",
            maql: "",
            sidxn: "",
            visible: false,
            tenBN: "",
            maBN: "",
            ngaySinh: "",
            dienThoai: "",
            diaChi: "",
            vsbChangePass: false,
            start: moment(),
            end: moment(),
            dateIn: moment(),
            disabledDienGiai: true,
            isResult: false,
            detail: {},
        }
    }
    componentDidMount() {
        this.setState({
            start: moment().subtract(1, 'months')
        }, () => this.DanhSachBenhNhan())
        this.ThongTinBenhNhan();
    }
    notifyUpdate = () => {
        this.DanhSachBenhNhan();
        this.KetQuaXetNghiem();
    }
    DanhSachBenhNhan() {
        Actions.setLoading(true);
        PatientService.LichSuXetNghiem(this.state.start.format('YYYY-MM-DD'), this.state.end.format('YYYY-MM-DD'), this.state.currentPage, this.state.currentSize).then(result => {
            if (result.isSuccess) {
                this.setState({ lstBN: result.data, totalRecords: result.totalRecords });
            }
            else {
                notification.warning({ message: "Đã xảy ra lỗi khi tải dữ liệu, vui lòng thử lại sau" });
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
        })
    }
    KetQuaXetNghiem() {
        Actions.setLoading(true);
        PatientService.KetQuaXetNghiem(this.state.maql, this.state.sidxn).then(result => {
            if (result.isSuccess) {
                this.setState({ lstTests: result.data });
            }
            else {
                notification.warning({ message: "Đã xảy ra lỗi khi tải dữ liệu, vui lòng thử lại sau" });
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
        })
    }
    checkColor(data) {
        if (isNaN(data.result) || isNaN(data.normalRangeMin) || isNaN(data.normalRangeMax) || data.result === "" || data.normalRangeMin === "" || data.normalRangeMax === "") {
            return;
        }
        else {
            if (Number(data.result) < Number(data.normalRangeMin)) {
                return 'card-highlight-low';
            } else if (Number(data.result) > Number(data.normalRangeMax)) {
                return 'card-highlight-high';
            } else {
                return 'card-highlight-normal';
            }
        }
    }
    handleSelectRecord = (record) => {
        this.setState({
            visible: true,
            maql: record.profileCode,
            sidxn: record.sid,
            dateIn: record.date ? moment(record.date) : moment(),
            disabledDienGiai: record.done === "1" ? false : true
        }, () => this.KetQuaXetNghiem());
    }
    ThongTinBenhNhan() {
        PatientService.ThongTinBenhNhan().then(result => {
            if (result.isSuccess) {
                const data = result.item;
                this.setState({
                    tenBN: data.name,
                    maBN: data.patientID,
                    ngaySinh: moment(data.dob).format("DD-MM-YYYY"),
                    dienThoai: data.phone,
                    diaChi: data.address
                });
            }
            else {
                notification.warning({ message: "Không thể tải thông tin cá nhân" });
            }
        }).catch(err => {
        })
    }
    contactInfo = (name, value, icon) => {
        return (
            <Tooltip title={name} color="#b15151e0">
                <div className="contact-card text-colorful">
                    <Divider><i className={icon} /></Divider>
                    <h5 className="text-center">{value ? value : "Chưa có thông tin"}</h5>
                </div>
            </Tooltip>
        )
    }
    normalRangeFilter(range = "") {
        const { ngaySinh } = this.state;
        var normalRange = "";
        if (range.length > 0) {
            if (range.includes("tuổi") && ngaySinh) {
                const age = Number(moment().year()) - Number(ngaySinh.substring(ngaySinh.length - 4));
                var arrayRange = range.split("\r\n");
                var arrayResult = [];
                var lowEnd = 0;
                var highEnd = 0;
                arrayRange.forEach(element => {
                    var ageRange = element.split("tuổi:")[0];
                    var valueRange = element.split("tuổi:")[1];
                    lowEnd = ageRange.split("-")[0];
                    highEnd = ageRange.split("-")[1];
                    var ageRangeArray = [];
                    for (var i = lowEnd; i <= highEnd; i++) {
                        ageRangeArray.push(Number(i));
                    }
                    arrayResult.push({ "age": ageRangeArray, "value": valueRange });
                });
                arrayResult.forEach(element => {
                    if (element.age.includes(age)) {
                        normalRange = `${element.value} (${lowEnd}-${highEnd} tuổi)`;
                    }
                });
                return normalRange;
            }
            return range;
        }
    }
    testDetail = (test, index) => {
        return (
            <div key={`child-card-${index}`} className={`test-card ${this.checkColor(test)}`}>
                <h4>{test.testName}</h4>
                <hr />
                <div className="col-md-12 d-flex"><span style={{ width: 100 }}>Đơn vị:</span>&nbsp;{test.unit}</div>
                <div className="col-md-12 d-flex"><span style={{ width: 100 }}>Kết quả:</span>&nbsp;{test.result}</div>
                <div className="col-md-12 d-flex"><span style={{ width: 100 }}>Bình thường:</span>&nbsp;{this.normalRangeFilter(test.normalRange)}</div>
                <div className="col-md-12 d-flex"><span style={{ width: 100 }}>Bất thường:</span>&nbsp;{test.isAbnormal}</div>
                <div className="col-md-12 d-flex"><span style={{ width: 100 }}>Mức nguy hiểm:</span>&nbsp;{test.normalRangeWarning}</div>
            </div>
        )
    }
    PrintDetailResultTest(sid = this.state.sidxn, maql = this.state.maql, ngay = this.state.dateIn) {
        PrintService.PrintDetailResultTest(sid, maql, ngay.format('YYYY/MM/DD')).then((result) => {
            Actions.DoPrint(result.item)
        })
    }
    render() {
        return (
            <React.Fragment >
                <div className="row patient" style={{ padding: '20px', overflowY: "scroll", height: "100vh" }}>
                    <Modal maskClosable={true}
                        width={1200}
                        visible={this.state.visible}
                        onCancel={() => this.setState({ visible: false })}
                        cancelText='Đóng'
                        cancelButtonProps={{ style: { display: 'none' } }}
                        okButtonProps={{ style: { display: 'none' } }}>
                        <div className="col-md-12">
                            <div className='h4 text-center mt-4 font-bold'>
                                KẾT QUẢ XÉT NGHIỆM
                                {!this.state.disabledDienGiai &&
                                    <button className="btn btn-info" style={{ position: "absolute", top: -11, right: 11 }}
                                        onClick={() => this.PrintDetailResultTest()}
                                        title="In diễn giải kết quả">
                                        <i className="fa fa-print" style={{ marginRight: 5 }} />Diễn giải kết quả xét nghiệm
                                    </button>}
                            </div>
                            <div>
                                {this.state.lstTests.map((test, index) => {
                                    return (
                                        test.listSubTestResult.length > 0 ?
                                            <div key={`parent-card-${index}`} className="card-parent">
                                                <h4>{`${test.testCategory} - ${test.testName}`}</h4>
                                                <div className="card-group row">
                                                    {test.listSubTestResult.map((sub, index) => {
                                                        return (
                                                            this.testDetail(sub, index)
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                            :
                                            <div key={`parent-card-${index}`} className="card-parent">
                                                <h4>{test.testCategory}</h4>
                                                <div className="card-group row">
                                                    {this.testDetail(test, index)}
                                                </div>
                                            </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="row mt-2" style={{ display: "flex", justifyContent: "center" }}>
                            <button className="btn btn-danger"
                                onClick={() => {
                                    this.setState({
                                        visible: false
                                    })
                                }}>
                                <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />Đóng
                            </button>
                        </div>
                    </Modal>
                    <div className="col-md-8 col-sm-12 mt-2 mb-2">
                        <div className="patient-info">
                            <Divider><i className="far fa-star" /></Divider>
                            <h2 className="font-bold text-uppercase text-colorful">{this.state.tenBN}</h2>
                            <h3 className="font-bold text-uppercase text-colorful">Mã: {this.state.maBN}</h3>
                            <div className="contact">
                                {this.contactInfo("Ngày sinh", this.state.ngaySinh, "fas fa-birthday-cake")}
                                {this.contactInfo("Địa chỉ", this.state.diaChi, "fas fa-map-marker-alt")}
                                {this.contactInfo("Điện thoại", this.state.dienThoai, "fas fa-phone")}
                            </div>
                        </div>
                        <div className="row mt-4" style={{ display: "flex", justifyContent: "center" }}>
                            <button className="btn btn-outline-success font-bold text-uppercase" onClick={() => { this.setState({ vsbChangePass: true }) }}><i className="fa fa-key" style={{ marginRight: 5 }} />Đổi mật khẩu</button>
                            <button className="btn btn-outline-danger font-bold text-uppercase" onClick={() => { Actions.logout(); return; }} style={{ marginLeft: 10 }} ><i className="fa fa-power-off" style={{ marginRight: 5 }} />Đăng xuất</button>
                        </div>
                        <Divider><i className="far fa-star" /></Divider>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <div className="blockContents">
                            <div className='h3 text-center mt-2 font-bold text-colorful'>LỊCH SỬ</div>
                            <Divider><i className="fas fa-flask text-colorful"></i></Divider>
                            <div className="row justify-content-center align-items-center mb-2">
                                <div className="col-auto">
                                    <DatePicker format="DD-MM-YYYY" value={this.state.start} allowClear={false}
                                        getPopupContainer={trigger => trigger.parentNode}
                                        className="rangePicker mb-2" placeholder="Từ ngày"
                                        locale={viVN} onChange={(value => this.setState({ start: value }))}
                                        disabledDate={(cur) => { return (this.state.end && cur && cur > this.state.end) }}
                                    />
                                </div>
                                <span>đến</span>
                                <div className="col-auto">
                                    <DatePicker format="DD-MM-YYYY" value={this.state.end} allowClear={false}
                                        getPopupContainer={trigger => trigger.parentNode}
                                        className="rangePicker mb-2" placeholder="Đến ngày"
                                        locale={viVN} onChange={(value => this.setState({ end: value }))}
                                        disabledDate={(cur) => { return (this.state.start && cur && cur < this.state.start) }}

                                    />
                                </div>
                                <div className="col-auto mb-2">
                                    <button type="button" className="btn btn-color" onClick={() => this.DanhSachBenhNhan()}>
                                        <i style={{ marginRight: 5 }} className="fa fa-search" />Tìm
                                    </button>
                                </div>
                            </div>
                            <div style={{ height: 'calc(100vh - 245px)', textAlign: "center", padding: 5, overflowY: "scroll" }}>
                                {
                                    this.state.lstBN.length > 0 && this.state.lstBN.map((record, index) => {
                                        return (
                                            <Button key={index}
                                                className="w-80"
                                                onClick={() => { }}
                                            >
                                                <div className="w-80-conent">
                                                    <div className="contentSID">
                                                        <div>SID: {record.sid}</div>
                                                        <div>{moment(record.date).format("hh giờ mm, DD-MM-YYYY")}</div>
                                                    </div>
                                                    {record.lichSuKham === true ?
                                                        <React.Fragment>
                                                            <div className="content-test"
                                                                onClick={() => {
                                                                    this.handleSelectRecord(record)
                                                                }}
                                                            >
                                                                <div className="text-hover">Xét nghiệm</div>
                                                            </div>
                                                            <div className="content-result"
                                                                onClick={() => {
                                                                    this.setState({ isResult: true, detail: record })
                                                                }}
                                                            >
                                                                <div className="text-hover">Khám bệnh</div>
                                                            </div>
                                                        </React.Fragment>
                                                        :
                                                        <div className="content-detail"
                                                            onClick={() => {
                                                                this.handleSelectRecord(record)
                                                            }}
                                                        >
                                                            <div className="text-hover">Xem chi tiết</div>
                                                        </div>
                                                    }
                                                </div>
                                            </Button>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <Modal visible={this.state.vsbChangePass}>
                        <ChangePassword open={this.state.vsbChangePass} onClose={() => this.setState({ vsbChangePass: false })}></ChangePassword>
                    </Modal>
                </div>
                {this.state.isResult &&
                    <Modal
                        visible={this.state.isResult}
                        onCancel={() => { this.setState({ isResult: false }) }}
                        width={1000}
                    >
                        <div style={{ backgroundColor: '#fff', padding: 5, borderRadius: 5, position: 'relative' }}>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <button
                                    className="btn btn-danger" 
                                    onClick={() => {
                                        this.setState({ isResult: false })
                                    }}
                                >Đóng</button>
                            </div>
                            <DetailsHistory mabn={this.state.detail.patientID} selectedTran={
                                {
                                    time: this.state.detail.date,
                                    maql: this.state.detail.profileCode
                                }
                            }></DetailsHistory>
                        </div>
                    </Modal>
                }
            </React.Fragment >
        )
    }
}