import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle, useCallback } from 'react';
import { Input, Button } from 'antd';
import Ocean from "../../components/background";
import { connect } from 'react-redux';
import * as AuthServices from '../../services/authServices';
import { ValidationCustom } from '../../commons/validationCustom';
import SimpleReactValidator from 'simple-react-validator';
import history from '../../stores/history';
import Notify, { NotifyStatus } from '../../components/notify';
import { Link } from 'react-router-dom';
import { isNotNull } from '../../libs/util';

const CoporationResult = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
    }));

    useEffect(() => {
        checkLogin();
    }, []);

    const _notify = useRef();
    const _username = useRef();
    const _password = useRef();
    const [, updateState] = React.useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const validator = useRef(new SimpleReactValidator(ValidationCustom));
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [showPassword, setShowPassword] = useState(false);

    const loginAction = () => {
        if (!validator.current.allValid()) {
            validator.current.showMessages();
            forceUpdate();
        } else {
            AuthServices.coporatorLogin(username, password).then((objRespone) => {
                if (objRespone.isSuccess === true) {
                    if (props.location && props.location.state) {
                        const { from } = props.location.state;
                        if (from !== '' && from !== undefined) {
                            history.push(from);
                        } else {
                            history.push('/ket-qua');
                        }
                    } else {
                        history.push('/ket-qua');
                    }
                } else {
                    _notify && _notify.current.Show(objRespone?.error.extensionData ?? "Không thể đăng nhập", NotifyStatus.Error);
                }
            })
        }
    }

    const checkLogin = () => {
        if (props === undefined || props.global === undefined || props.global.token === undefined) return;
        if (props && props.global.token !== '') {
            if (props.location && props.location.state) {
                const { from } = props.location.state;
                if (from !== '' && from !== undefined) {
                    history.push(from);
                } else {
                    history.push('/ket-qua');
                }
            } else {
                history.push('/ket-qua');
            }
        }
    }

    const handlePressEnter = (e) => {
        if (e.key == "Enter") {
            if (isNotNull(username) && isNotNull(password))
                loginAction();
            else if (!isNotNull(username)) _username.current.focus();
            else if (!isNotNull(password)) _password.current.focus();
        }
    }

    return (
        <React.Fragment>
            <div className='container-fluid h-100 d-flex flex-column mita-container'>
                <Ocean />
                <div className="mt-2">
                    <Notify ref={_notify} />
                </div>
                <div className='d-flex flex-column justify-content-center align-items-center w-100'>
                    <div className="text-center mrt-2">
                        <h1>Kết quả trực tuyến khách hàng phòng khám</h1>
                        <div className='mita-text'>Vui lòng đăng nhập vào hệ thống để tra cứu các kết quả của bệnh nhân</div>
                    </div>
                    <div className='mrt-1 mita-input-check'>
                        <div className='mita-text mita-required'>Tài khoản</div>
                        <Input
                            ref={_username}
                            className='w-100 mita-input mita-text'
                            placeholder='Nhập tên đăng nhập'
                            value={username}
                            onChange={(e) => { setUsername(e.target.value); }}
                            onKeyDown={(e) => { handlePressEnter(e)}}
                        ></Input>
                        {validator.current && validator.current.message('Tài khoản', username, 'required')}
                    </div>
                    <div className='mt-4 mita-input-check'>
                        <div className='mita-text mita-required'>Mật khẩu</div>
                        <i className={showPassword ? "far fa-eye showPassword" : "far fa-eye-slash showPassword"}
                            onClick={() => setShowPassword(!showPassword)} />
                        <Input
                            ref={_password}
                            className='w-100 mita-input mita-text'
                            placeholder='Nhập mật khẩu'
                            value={password}
                            type={showPassword ? "text" : "password"}
                            onChange={(e) => { setPassword(e.target.value); }}
                            onKeyDown={(e) => { handlePressEnter(e); }}
                        ></Input>
                        {validator.current && validator.current.message('Mật khẩu', password, 'required')}
                    </div>
                    <div className='text-center'>
                        <Button className='mita-btn-round-main mrt-1'
                            onClick={() => { loginAction(); }}
                        >Đăng nhập</Button>
                    </div>
                    <div className='text-center pdb-1'>
                        <Link to="/">
                            <Button className='mita-btn-round-sub mrt-1'>
                                Bạn là khách hàng cá nhân
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
})

function mapStateToProps(state, props) {
    return {
        global: state.global
    };
}

export default connect(mapStateToProps)(CoporationResult)