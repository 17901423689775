import { Table } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import * as Actions from '../../../libs/actions';
import * as CommonService from '../../../services/commonService';
import * as TestService from '../../../services/lisService';

export default class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            maql: '',
            ngay: moment(),
            expandableRow: [],
            sidxn: '',
            lstKetQua: [],
            idbenhtrinh: this.props.idbenhtrinh ? this.props.idbenhtrinh : '',
        }
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.sidxn !== this.props.sidxn) {
            this.setState({ ngay: nextProps.ngay, maql: nextProps.maql, sidxn: nextProps.sidxn });
        }
        if (nextProps.idbenhtrinh !== this.props.idbenhtrinh) {
            this.setState({ idbenhtrinh: nextProps.idbenhtrinh }, () => { this.ThongTinKetQua() });
        }
        return true;
    }
    static getDerivedStateFromProps(props, state) {
        return {
            maql: props.maql,
            ngay: props.ngay,
            sidxn: props.sidxn,
        }
    }
    componentDidMount() {
        this.ThongTinKetQua();
    }
    CapNhatKetQua() {
        Actions.setLoading(true);
        CommonService.GetDataFromLis(this.state.maql, this.state.sidxn).then(result => {
            if (result.isSuccess) {
                this.ThongTinKetQua();
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err);
            Actions.setLoading(false);
        })
    }

    ThongTinKetQua() {
        Actions.setLoading(true);
        let noitru = this.props.noitru ? this.props.noitru : false;
        if (noitru !== true && this.state.sidxn !== '') {
            TestService.ThongTinKetQua(this.state.maql, this.state.sidxn, noitru).then(result => {
                if (result.isSuccess) {
                    let data = result.data;
                    if (noitru)
                        data = data.filter(x => x.idbenhtrinh === this.state.idbenhtrinh);
                    this.setState({ lstKetQua: data });
                }
                else {
                    Actions.openMessageDialog("Lỗi", result.err.msgString);
                }
                Actions.setLoading(false);
            }).catch(err => {
                Actions.openMessageDialog("Lỗi", err);
                Actions.setLoading(false);
            })
        } else {
            TestService.ThongTinKetQuaTheoBenhTrinh(this.state.idbenhtrinh).then(result => {
                if (result.isSuccess) {
                    let data = result.data;
                    if (noitru)
                        data = data.filter(x => x.idbenhtrinh === this.state.idbenhtrinh);
                    this.setState({ lstKetQua: data });
                }
                else {
                    Actions.openMessageDialog("Lỗi", result.err.msgString);
                }
                Actions.setLoading(false);
            }).catch(err => {
                Actions.openMessageDialog("Lỗi", err);
                Actions.setLoading(false);
            })
        }
    }
    checkColor(data) {
        if (isNaN(data.ketQua) || isNaN(data.mucBinhThuongMin) || isNaN(data.mucBinhThuongMax) || data.ketQua === "" || data.mucBinhThuongMin === "" || data.mucBinhThuongMax === "") {
            return 'normal';
        }
        else {
            if (Number(data.ketQua) < Number(data.mucBinhThuongMin)) {
                return 'bold';
            } else if (Number(data.ketQua) > Number(data.mucBinhThuongMax)) {
                return 'bold';
            }
        }
        return 'normal';
    }
    setExpandable(index) {
        let lst = this.state.expandableRow;
        if (lst.length === 0) {
            this.setState({ expandableRow: [index] });
            return;
        }
        let old = lst.indexOf(index);
        if (old < 0) {
            lst.push(index);
            this.setState({ expandableRow: lst })
        }
        else {
            lst = lst.filter(x => x !== index);
            this.setState({ expandableRow: lst })
        }
    }
    render() {
        const columns = [
            {
                title: 'Mã dịch vụ',
                dataIndex: 'maDV',
                key: 'maDV',
                width: 100,
                render: (value, row) => (<div style={{ fontWeight: `${this.checkColor(row)}` }}>{value}</div>)
            },
            {
                title: 'Tên dịch vụ',
                dataIndex: 'tenDichVu',
                key: 'tenDichVu',
                width: 150,
                render: (value, row) => (<div style={{ fontWeight: `${this.checkColor(row)}` }}>{value}</div>)
            },
            {
                title: 'Kết quả',
                dataIndex: 'ketQua',
                key: 'ketQua',
                width: 100,
                render: (value, row) => (<div style={{ fontWeight: `${this.checkColor(row)}` }}>{value}</div>)
            },
            { title: 'Bình thường', dataIndex: 'normalRange', width: 100, key: 'normalRange' },
            { title: 'Đơn vị', dataIndex: 'unit', width: 100, key: 'unit' },
            { title: 'Bất thường', dataIndex: 'batThuong', width: 100, key: 'batThuong' },
            { title: 'Mức nguy hiểm', dataIndex: 'normalRangeWarning', width: 120, key: 'normalRangeWarning' },
        ];
        const columnsChild = [
            {
                title: 'Mã dịch vụ',
                dataIndex: 'maDV',
                key: 'maDV',
                render: (value, row) => (<div style={{ fontWeight: `${this.checkColor(row)}` }}>{value}</div>)
            },
            {
                title: 'Tên dịch vụ',
                dataIndex: 'tenDichVu',
                key: 'tenDichVu',
                render: (value, row) => (<div style={{ fontWeight: `${this.checkColor(row)}` }}>{value}</div>)
            },
            {
                title: 'Kết quả',
                dataIndex: 'ketQua',
                key: 'ketQua',
                width: 100,
                render: (value, row) => (<div style={{ fontWeight: `${this.checkColor(row)}` }}>{value}</div>)
            },
            { title: 'Đơn vị', dataIndex: 'unit', width: 100, key: 'unit' },
            { title: 'Bình thường', dataIndex: 'normalRange', width: 100, key: 'normalRange' },
            { title: 'Bất thường', dataIndex: 'batThuong', width: 100, key: 'batThuong' },
            { title: 'Mức nguy hiểm', dataIndex: 'normalRangeWarning', width: 120, key: 'normalRangeWarning' },
        ];
        return (
            <React.Fragment>
                {this.props.patientName &&
                    <div className="h4 text-center font-bold text-uppercase">Kết quả xét nghiệm của {this.props.patientName}</div>
                }
                {this.state.lstKetQua.length === 0 ?
                    <div>
                        <p>Bệnh nhân {this.props.patientName} không có xét nghiệm nào</p>
                        {this.props.history !== true && <div style={{ textAlign: "center", marginTop: "10px" }}>
                            <button className="btn btn-danger" style={{ marginLeft: 10 }}
                                onClick={() => {
                                    if (this.props.onCancel)
                                        this.props.onCancel()
                                }}>
                                <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }}
                                /> Đóng
                            </button>
                        </div>}
                    </div>
                    :
                    <div style={{ marginTop: "5px" }}>
                        <Table
                            className="components-table-demo-nested"
                            scroll={{ x: 'max-content' }}
                            columns={columns}
                            style={{ fontSize: '14px' }}
                            expandable={{
                                expandedRowRender: (record) => (
                                    <div className='expandBorder' style={{ marginTop: "-7px", marginBottom: '-7px', marginLeft: 35, fontStyle: 'italic' }}>
                                        <Table
                                            scroll={{ x: 'max-content' }}
                                            onRow={(row, rowIndex) => {
                                                return {
                                                    onClick: e => { },
                                                };
                                            }}
                                            style={{ marginLeft: '7px' }} showHeader={false} columns={columnsChild} dataSource={record.listSubTestResult} pagination={false} rowKey={(row, index) => index} />
                                    </div>
                                ), rowExpandable: record => record.listSubTestResult.length > 0,
                            }}
                            dataSource={this.state.lstKetQua}
                            rowKey={(row, index) => index}
                            pagination={false}
                            rowClassName={(row, index) => {
                                const xx = index % 2 === 0 ? 'rowCustomSelectNewEven' : 'rowCustomSelectNewOdd'
                                return xx
                            }}
                            expandRowByClick={true}
                            expandedRowKeys={this.state.expandableRow}
                            onRow={(record, index) => {
                                return {
                                    onClick: e => {
                                        this.setExpandable(index);
                                    }
                                };
                            }}
                        />
                        {this.props.history !== true && <div style={{ textAlign: "center", marginTop: "10px" }}>
                            <button className="btn btn-success" onClick={() => this.CapNhatKetQua()}>Cập nhật</button>

                            <button className="btn btn-danger" style={{ marginLeft: 10 }}
                                onClick={() => {
                                    if (this.props.onCancel)
                                        this.props.onCancel()
                                }}>
                                <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }}
                                /> Đóng
                            </button>
                        </div>}
                    </div>
                }
            </React.Fragment>
        )
    }
}
