import React, { Fragment, forwardRef, useEffect, useImperativeHandle, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { groupBy, renumber, isNotNull } from '../../libs/util';
import moment from 'moment';
import NoContent from '../../components/loading/noContent';

const MitaTable = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
    }));

    const [, updateState] = React.useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const [columns, setColumns] = useState(props.columns ?? []);
    const [listSub, setListSub] = useState(props.listGroup);
    const [data, setData] = useState(props.data);
    const [groupOn,] = useState(props.groupOn);

    useEffect(() => {
        if (isNotNull(props.groupOn)) {
            const listGroup = groupBy(props.data, props.groupOn);
            const listGroupArr = Object.keys(listGroup).map((key) => ({ key, value: listGroup[key] }));
            const listGroupArrRenumber = renumber(listGroupArr);
            setData(listGroupArrRenumber);
        }
        setColumns(props.columns);
        setListSub(props.listSub);
        forceUpdate();
    }, [props.data, props.columns, props.groupOn, props.listSub]);

    const renderData = (key, data, render) => {
        if (typeof render === 'function') {
            return data[key] != undefined && render(data[key], data);
        } else {
            return data[key] ?? "";
        }
    }

    const onRowClick = (rowData) => {
        props.onRowClick && props.onRowClick(rowData);
    }

    const getGroupValue = (value) => {
        if (moment(value).isValid()) return moment(value).format("DD/MM/YYYY");
        else return <span className='mita-text-bold'>{value}</span>;
    }

    return (
        !isNotNull(data) ?
            <NoContent title="Không có dữ liệu phù hợp" message="Không có dữ liệu" />
            :
            <table className='mita-table'>
                <thead>
                    <tr>
                        {
                            columns && columns.map((col) => {
                                return (
                                    <td key={col.dataIndex} style={{ width: col.width ?? "fit-content", textAlign: col.align }} className='mita-text'>{col.title}</td>
                                )
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        data && data.map((data, index) => {
                            return (
                                isNotNull(groupOn) ?
                                    <Fragment>
                                        {
                                            data.value && data.value.length > 0 &&
                                            <tr key={data.key} className='mita-text mita-headRow'>
                                                <td colspan={columns?.length ?? 1}>{getGroupValue(data.key)}</td>
                                            </tr>
                                        }
                                        {
                                            data.value && data.value.map((sub, index) => {
                                                return (
                                                    <Fragment>
                                                        <tr key={index} className={index % 2 == 0 ? "mita-hover mita-evenRow" : "mita-hover mita-oddRow"}
                                                            onClick={() => onRowClick(sub)}>
                                                            {
                                                                columns && columns.map((col) => {
                                                                    return (
                                                                        <td className='mita-text' style={{ textAlign: col.align }}>{renderData(col.dataIndex, sub, col.render)}</td>
                                                                    )
                                                                })
                                                            }
                                                        </tr>
                                                        {
                                                            sub[`${listSub}`] && sub[`${listSub}`].map((subSec, indexSec) => {
                                                                return (
                                                                    <tr key={`sub-${indexSec}`} className={index % 2 == 0 ? "mita-hover mita-evenRow" : "mita-hover mita-oddRow"}>
                                                                        {
                                                                            columns && columns.map((col) => {
                                                                                return (
                                                                                    <td className='mita-text pl-2' style={{ textAlign: col.align }}>{renderData(col.dataIndex, subSec, col.render)}</td>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </Fragment>
                                                )
                                            })
                                        }
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <tr key={index} className={index % 2 == 0 ? "mita-hover mita-evenRow" : "mita-hover mita-oddRow"}
                                            onClick={() => onRowClick(data)}>
                                            {
                                                columns && columns.map((col) => {
                                                    return (
                                                        <Fragment>
                                                            <td className='mita-text' style={{ textAlign: col.align }}>{renderData(col.dataIndex, data, col.render)}</td>
                                                        </Fragment>
                                                    )
                                                })
                                            }
                                        </tr>
                                        {
                                            data[`${listSub}`] && data[`${listSub}`].map((subSec, indexSec) => {
                                                return (
                                                    <tr key={`sub-${indexSec}`} className={index % 2 == 0 ? "mita-hover mita-evenRow" : "mita-hover mita-oddRow"}>
                                                        {
                                                            columns && columns.map((col) => {
                                                                return (
                                                                    <td className='mita-text pl-2' style={{ textAlign: col.align }}>{renderData(col.dataIndex, subSec, col.render)}</td>
                                                                )
                                                            })
                                                        }
                                                    </tr>
                                                )
                                            })
                                        }
                                    </Fragment>
                            )
                        })
                    }
                </tbody>
            </table>
    );
})

const mapStateToProps = (state) => {
    return ({
        global: state.global,
        router: state.router,
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(MitaTable);