import * as ApiCaller from "../libs/httpRequests";

const prefix = 'PatientPortal';

export function CoporatorListResult(filterModel) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/GetListPatient`, filterModel).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function CoporatorResultSummary(filterModel) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/ResultSummary`, filterModel).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    })
};

export function CoporatorPatientDetail(patientCode, managermentId) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/PatientInfo?patientCode=${patientCode}&managementId=${managermentId}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function CoporatorPatientListResult(patientCode, managermentId) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/PatientResultGroupServices?patientCode=${patientCode}&managementId=${managermentId}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function ExportPatientResult(patientCode, managermentId, groupId) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/ExportPatientResult?patientCode=${patientCode}&managementId=${managermentId}&group=${groupId}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function PrintPatientResult(patientCode, managermentId, groupId) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/PrintPatientResult?patientCode=${patientCode}&managementId=${managermentId}&group=${groupId}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            return reject(err)
        })
    });
};

export function CoporatorListFilePatient(patientCode, managermentId, groupId, dateIn) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/LisResultFile?patientCode=${patientCode}&managementId=${managermentId}&group=${groupId}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            return reject(err)
        })
    });
};

export function DownloadLisFile(uuid) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/DownloadLisFile?uuid=${uuid}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};