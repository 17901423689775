import React, { useCallback, useRef, forwardRef, useEffect, useImperativeHandle, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import * as CoporatorService from "../../services/coporatorService";
import Notify, { NotifyStatus } from '../../components/notify';
import moment from 'moment';
import { isNotNull } from '../../libs/util';

const PatientInfo = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
    }));

    useEffect(() => {
        if (isNotNull(props.patientCode) && isNotNull(props.managementId))
            getPatientInfo(props.patientCode, props.managementId)
    }, [props.patientCode, props.managementId]);

    const _notify = useRef();
    const [, updateState] = React.useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const [patientInfo, setPatientInfo] = useState({})

    const getPatientInfo = (patientCode = props.patientCode, managementId = props.managementId) => {
        CoporatorService.CoporatorPatientDetail(patientCode, managementId).then(result => {
            if (result.isSuccess) {
                let data = result.data;
                setPatientInfo(data);
                _notify && _notify.current && _notify.current.Close();
            } else {
                if (props.showNotification) props.showNotification("Không thể tải thông tin cá nhân", NotifyStatus.Error);
                else _notify && _notify.current && _notify.current.Show("Không thể tải thông tin cá nhân", NotifyStatus.Error);
            }
        }).catch(err => {
            if (props.showNotification) props.showNotification(err.error.messageText, NotifyStatus.Error);
            else _notify && _notify.current && _notify.current.Show(err.error.messageText, NotifyStatus.Error);
        }).finally(() => { })
    }

    return (
        <Fragment>
            <Notify ref={_notify} />
            <div className='mita-patient-info'>
                <div className='d-flex justify-content-between'>
                    <label className='mita-text mita-title'>Họ tên:</label>
                    <label className='mita-text mita-value'>{patientInfo.patientName ?? "Chưa có thông tin"}</label>
                </div>
                <div className='d-flex justify-content-between'>
                    <label className='mita-text mita-title'>Điện thoại:</label>
                    <label className='mita-text mita-value'>{patientInfo.phoneNumber ?? "Chưa có thông tin"}</label>
                </div>
                <div className='d-flex justify-content-between'>
                    <label className='mita-text mita-title'>{patientInfo.onlyYear ? "Năm sinh" : "Ngày sinh"}:</label>
                    <label className='mita-text mita-value'>{patientInfo.dateOfBirth ? patientInfo.onlyYear ? moment(patientInfo.dateOfBirth).format("YYYY") : moment(patientInfo.dateOfBirth).format("DD/MM/YYYY") : "Chưa có thông tin"}</label>
                </div>
                <div className='d-flex justify-content-between'>
                    <label className='mita-text mita-title'>Địa chỉ:</label>
                    <label className='mita-text mita-value'>{patientInfo.address ?? "Chưa có thông tin"}</label>
                </div>
                <div className='d-flex justify-content-between'>
                    <label className='mita-text mita-title'>Giới tính:</label>
                    <label className='mita-text mita-value'>{patientInfo.gender ?? "Chưa có thông tin"}</label>
                </div>
                {
                    patientInfo.diagnose &&
                    <div className='d-flex justify-content-between'>
                        <label className='mita-text mita-title'>Chẩn đoán ban đầu:</label>
                        <label className='mita-text mita-value'>{patientInfo.diagnose}</label>
                    </div>
                }
            </div>
        </Fragment>
    );
})

const mapStateToProps = (state) => {
    return ({
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(PatientInfo);