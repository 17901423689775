import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { connect } from 'react-redux';
import * as CoporatorService from "../../services/coporatorService";
import moment from 'moment';
import { NotifyStatus } from '../notify';

const Summary = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        onSearch: (filterModel) => {
            onSearch(filterModel);
        }
    }));

    const [listSummary, setListSummary] = useState([]);

    const onSearch = (filterModel) => {
        if (filterModel.dateFrom && filterModel.dateTo) {
            if (moment(filterModel.dateFrom) > moment(filterModel.dateTo)) {
                props.showNotification && props.showNotification("Ngày bắt đầu phải nhỏ hơn ngày kết thúc");
                return;
            }
        }
        CoporatorService.CoporatorResultSummary(filterModel).then(result => {
            if (result.isSuccess) {
                let data = result.data;
                setListSummary(data);
            } else {
                props.showNotification && props.showNotification(result.error.messageText, NotifyStatus.Error);
            }
        }).catch(err => {
            props.showNotification && props.showNotification(err.error.messageText, NotifyStatus.Error);
        }).finally(() => { })
    }

    return (
        <div className={`${props.className ?? ""} mita-result-summary mrt-1`}>
            {
                listSummary && listSummary.map((detail, index) => {
                    return (
                        <div className='mita-result-summary-item' key={`result-summary-${index}`}>
                            <label className='mita-title mita-text'>{detail.title}</label>
                            <label className='mita-value-card'><span className='mita-text mita-text-white'>{detail.amount}</span></label>
                        </div>
                    )
                })
            }
        </div>
    );
})

const mapStateToProps = (state) => {
    return ({
        global: state.global,
        router: state.router,
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(Summary);