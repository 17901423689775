import * as ApiCaller from '../libs/httpRequests';

const prefix = 'chidinh';
export function DanhSachLoaiChiDinh(key="" , group="")  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/danhsachloaichidinh?key=${key}&group=${group}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function ThongTinThuThuatBenhNhan(mabn,maql,mavp,ngay, idchidinh='')  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/ThongTinThuThuatBenhNhan?mabn=${mabn}&maql=${maql}&mavp=${mavp}&ngay=${ngay}&idchidinh=${idchidinh}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function ThongTinDongYBenhNhan(mabn,maql,mavp,ngay,idchidinh='')  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/ThongTinDongYBenhNhan?mabn=${mabn}&maql=${maql}&mavp=${mavp}&ngay=${ngay}&idchidinh=${idchidinh}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function DanhSachLoaiPhauThat(key="")  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachLoaiPhauThat?key=${key}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function DanhSachTenPhauThat(key="")  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachTenPhauThat?key=${key}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function DanhSachChiDinh(key="",loai="",doituong,sothe,makp)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/danhsachchidinh?key=${key}&loai=${loai}&doituong=${doituong}&sothe=${sothe}&makp=${makp}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function XoaChiDinh(id,ngay) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/xoachidinh?id=${id}&ngay=${ngay}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function DanhSachChiDinhBenhNhan(mabn,maql,makp,ngay,thuthuat=false, type = 0, subType=0, isNoiTru=false, idbenhtrinh = "")  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/danhsachchidinhbenhnhan?mabn=${mabn}&maql=${maql}&makp=${makp}&ngay=${ngay}&thuthuat=${thuthuat}&type=${type}&subType=${subType}&isNoiTru=${isNoiTru}&idbenhtrinh=${idbenhtrinh}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function DanhSachThuThuatBenhNhan(mabn,maql,ngay,mavp="")  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`Surgery/DanhSachThuThuatBenhNhan?mabn=${mabn}&maql=${maql}&ngay=${ngay}&mavp=${mavp}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function DanhSachDongYBenhNhan(mabn,maql,ngay,mavp="")  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`Surgery/DanhSachDongYBenhNhan?mabn=${mabn}&maql=${maql}&ngay=${ngay}&mavp=${mavp}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function ThongTinChiDinh(id,doituong,mabn,makp)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/thongtinchidinh?id=${id}&doituong=${doituong}&mabn=${mabn}&makp=${makp}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function ThongTinChiDinhCDHA(id,doituong,mabn,makp)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/ThongTinChiDinhCDHA?id=${id}&doituong=${doituong}&mabn=${mabn}&makp=${makp}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function GetPhongKham(pageNumber =1, pageSize = 200)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`Common/phongkham?pageNumber=${pageNumber}&pageSize=${pageSize}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function LuuChiDinh(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/luuchidinh`,data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function LuuThuThuat(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/LuuThuThuat`,data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function LuuCapThuoc(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/luucapthuoc`,data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function DanhSachThuoc(doituong,ngay,key,nguon,kho)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/danhsachthuoc?doituong=${doituong}&ngayvv=${ngay}&key=${key}&nguon=${nguon}&kho=${kho}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function DanhSachThuocBanle(doituong,key,nguon,kho, noitru=false)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/danhsachthuocbanle?doituong=${doituong}&&key=${key}&nguon=${nguon}&kho=${kho}&noitru=${noitru}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function DanhSachCachDung(key)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/danhsachcachdung?key=${key}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};


export function DanhSachCapThuocBenhNhan(id,mabn,maql,ngay,isBacsi=false)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/danhsachcapthuocbenhnhan?id=${id}&mabn=${mabn}&maql=${maql}&ngay=${ngay}&isBacsi=${isBacsi}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function ThongTinKetLuan(maql) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/ThongTinKetLuan?maql=${maql}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function LuuKetLuan(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/luuketluan`,data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function LuuKetLuanNew(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/luuketluannew`,data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function searchSerivce(group , type , banggia, name, register=false)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/searchSerivce?type=${type}&name=${name}&banggia=${banggia}&group=${group}&register=${register}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function getExamService()  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/getExamService`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};



export function DanhSachPhongKhamTheoLoai()  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachPhongKhamTheoLoai`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};


export function DanhSachPKBenh()  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachPKBenh`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};


export function LoaiPhongKhamTheoMaVP(idvp)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/LoaiPhongKhamTheoMaVP?idvp=${idvp}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function ThongTinKetLuanHenKham(mabn, maql)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/ThongTinKetLuanHenKham?mabn=${mabn}&maql=${maql}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function getAllTypeService()  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/getAllTypeService`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function getGroups(type)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/getGroups?type=${type}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function TimKiemNhanhDichVu(key, page, size)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/TimKiemNhanhDichVu?key=${key}&page=${page}&size=${size}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function DanhSachThuocBanLeTrongGoiThuoc(idgoithuoc,nguon,kho, noitru=false)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachThuocBanLeTrongGoiThuoc?idgoithuoc=${idgoithuoc}&nguon=${nguon}&kho=${kho}&noitru=${noitru}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
