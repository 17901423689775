import React from 'react';
import './custom.css';
import InnerHTML from 'dangerously-set-html-content'

export default class Printer extends React.Component {
    constructor(props) {
        super(props);
        this.state = { file: null };
    }
    componentDidMount() {

    }
    static getDerivedStateFromProps(props, current_state) {

        if (current_state.file == null && props.file != null) {
            setTimeout(() => {
                window.print()
            }, 1000)

        }
        return {
            file: props.file
        }
    }
    render() {

        return (
            <React.Fragment>
                { this.state.file !== null && this.state.file !== '' &&
                    <div className="printarea" style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, zIndex: 300, }}>
                        <InnerHTML style={{ width: '100%', height: '100%' }} html={this.props.file} />
                        {/* <div>{ ReactHtmlParser(this.props.file) }</div> */}
                    </div>
                }
            </React.Fragment>
        );
    }
}
