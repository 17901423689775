import React, { useCallback, useRef, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { connect } from 'react-redux';
import * as Actions from '../../libs/actions';
import Notify, { NotifyStatus } from '../../components/notify';
import * as CoporatorService from "../../services/coporatorService";
import PatientInfo from "./patientInfo";
import history from '../../stores/history';
import { isNotNull } from '../../libs/util';
import DetailByGroup from './detailByGroup';
import { GROUP_ID_TEST } from '../../commons/constanst';
import { Button, Modal } from 'antd';
import ViewFile from './viewFile';
import HeaderModal from '../../components/headerModal';

const columnsTest = [
    {
        title: '#',
        dataIndex: 'stt',
        key: 'stt',
        width: 80,
        align: 'center',
        render: (idx) => Number(idx),
    },
    {
        title: 'Dịch vụ',
        dataIndex: 'service',
        key: 'service',
        width: 250,
        align: 'left',
    },
    {
        title: 'Kết quả',
        dataIndex: 'result',
        key: 'result',
        width: 100,
        align: "center",
        render: (res, source) => {
            if (source.mark == "High") return <div style={{ textAlign: "right", fontWeight: "bold" }}>{res} H</div>
            else if (source.mark == "Low") return <div style={{ textAlign: "left", fontWeight: "bold" }}>{res} L</div>
            else return <div>{res}</div>
        }
    },
    {
        title: 'Chỉ số bình thường',
        dataIndex: 'normalRange',
        key: 'normalRange',
        width: 200,
        align: 'center',
    },
    {
        title: 'Đơn vị tính',
        dataIndex: 'unit',
        key: 'unit',
        width: 100,
        align: 'center',
    },
    {
        title: 'Tiền sử',
        dataIndex: 'lastResult',
        key: 'lastResult',
        width: 100,
        align: 'center',
    },
]

const columnsPacs = [];

const CoporatorResultDetail = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
    }));

    useEffect(() => {
        let data = history.location.state?.data ?? [];
        if (isNotNull(data)) {
            data.patientCode && setPatientCode(data.patientCode);
            data.managementId && setmanagementId(data.managementId);
        }
        onSearchResultOfPatient(data.patientCode, data.managementId);
    }, [history.location.state]);

    const [, updateState] = React.useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    const _notify = useRef();
    const _reviewFile = useRef();
    const [patientCode, setPatientCode] = useState();
    const [managementId, setmanagementId] = useState();
    const [groupId, setGroupId] = useState();
    const [viewFile, setViewFile] = useState(false);
    const [dataList, setDataList] = useState([]);
    const [currentActiveTab, setCurrentActiveTab] = useState(0);

    const showNotification = (message, status = NotifyStatus.Warning) => {
        _notify && _notify.current && _notify.current.Show(message, status);
    }

    const handleChangeTab = (data, index) => {
        setCurrentActiveTab(index);
        setGroupId(data.groupId);
        if (isTest(data.groupId)) setColumns(columnsTest)
        else setColumns(columnsPacs);
    }

    const [columns, setColumns] = useState(columnsTest);

    const isTest = (groupId) => {
        return groupId == GROUP_ID_TEST;
    }

    const onSearchResultOfPatient = (pid = patientCode, mid = managementId) => {
        Actions.setLoading(true);
        CoporatorService.CoporatorPatientListResult(pid, mid).then(result => {
            if (result.isSuccess) {
                let data = result.data ?? [];
                setDataList(data);
                _notify && _notify.current && _notify.current.Close();
            } else {
                _notify && _notify.current && _notify.current.Show(result.error.extensionData, NotifyStatus.Error);
            }
        }).catch(err => {
            _notify && _notify.current && _notify.current.Show(err.error.messageText, NotifyStatus.Error);
        }).finally(() => {
            Actions.setLoading(false);
        })
    }

    const handleExport = () => {
        Actions.setLoading(true);
        let group = groupId ?? dataList[0]?.groupId;
        CoporatorService.ExportPatientResult(patientCode, managementId, group).then((response) => {
            if (response.isSuccess)
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    this._hiddenLink.href = url;
                    this._hiddenLink.download = "KetQua";
                    this._hiddenLink.click();
                })
            else
                _notify && _notify.current && _notify.current.Show(response.error.extensionData, NotifyStatus.Error);
        }).catch((err) => {
            _notify && _notify.current && _notify.current.Show(err.error.messageText, NotifyStatus.Error);
        }).finally(() => {
            Actions.setLoading(false);
        })
    }

    const handlePrint = () => {
        Actions.setLoading(true);
        let group = groupId ?? dataList[0]?.groupId;
        CoporatorService.PrintPatientResult(patientCode, managementId, group).then((result) => {
            if (result.isSuccess)
                Actions.DoPrint(result.item)
            else
                _notify && _notify.current && _notify.current.Show(result.error.extensionData, NotifyStatus.Error);
        }).catch((err) => {
            _notify && _notify.current && _notify.current.Show(err.error.messageText, NotifyStatus.Error);
        }).finally(() => {
            Actions.setLoading(false);
        })
    }

    const handleViewFileResult = () => {
        _reviewFile && _reviewFile.current && _reviewFile.current.onSearch && _reviewFile.current.onSearch();
        setViewFile(true);
    }

    const onCloseModal = () => {
        setViewFile(false);
    }

    const onShowFile = () => {
        _reviewFile && _reviewFile.current && _reviewFile.current.onShowFile && _reviewFile.current.onShowFile();
    }

    return (
        <div className='mita-container h-100 d-flex flex-column'>
            <Notify className="mt-2" ref={_notify} />
            <div className='mrt-1'>
                <PatientInfo patientCode={patientCode} managementId={managementId}
                    showNotification={(message, status) => showNotification(message, status)} />
            </div>
            <ul className='mita-tab-group mrt-1'>
                {
                    dataList && dataList.map((data, index) => {
                        return (
                            <li key={index}
                                className={`mita-tab mita-text ${index == currentActiveTab ? "active" : ""}`}
                                onClick={() => {
                                    handleChangeTab(data, index);
                                }}
                            >{data.group}
                            </li>
                        )
                    })
                }
            </ul>
            <div>
                <DetailByGroup patientCode={patientCode} managementId={managementId}
                    columns={columns} groupOn="type" data={dataList[currentActiveTab]?.listResult} listSub="listSub"
                    showNotification={(message, status) => showNotification(message, status)}
                    handlePrint={() => { handlePrint() }}
                    handleExport={() => { handleExport() }}
                    handleView={() => { handleViewFileResult() }} />
            </div>
            <Modal
                className='mita-modal'
                title={<HeaderModal title="Vui lòng chọn file cần xem" onClose={() => { onCloseModal(); }} />}
                open={viewFile}
                destroyOnClose
                onCancel={() => onCloseModal()}
                okText="OK"
                cancelText="Hủy"
                onOk={() => {}}
                centered
                width={900}
                footer={
                    <div>
                        <Button className='mita-btn-round-sub' onClick={() => { onCloseModal(); }}>Hủy</Button>
                        <Button className='mita-btn-round-main ml-2' onClick={() => onShowFile() }>OK</Button>
                    </div>
                }
            >
                <ViewFile ref={_reviewFile}
                    patientCode={patientCode} managementId={managementId}
                    groupId={groupId ?? dataList[0]?.groupId}
                    showNotification={(err, status) => showNotification(err, status)}
                    closeModal={() => onCloseModal()} />
            </Modal>
        </div>
    );
})

const mapStateToProps = (state) => {
    return ({
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(CoporatorResultDetail);