import React, { Fragment, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { connect } from 'react-redux';
import "./style.scss";
import moment from 'moment';
import { logout } from '../../libs/actions';
import { Link } from 'react-router-dom';
import { Icons } from "../../libs/icons";
import { Button, Drawer, Modal, Popover, notification } from 'antd';
import ChangePassword from '../../screens/coporation-examresult/changePassword';
import HeaderModal from '../headerModal';
import { UserOutlined } from '@ant-design/icons';

const Header = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
    }));
    const [activeTab, setActiveTab] = useState("");
    const [menu,] = useState([
        {
            name: "Cá nhân",
            toPage: "/",
        },
        {
            name: "Phòng khám",
            toPage: "/login",
        }
    ]);
    const [openUserAction, setOpenUserAction] = useState(false);
    const [visiblePasswordChange, setVisiblePasswordChange] = useState(false);
    const [openMenu, setOpenMenu] = useState(false);

    useEffect(() => {
        getActiveTab(props.router);
    }, [props.router]);

    const isLogged = () => {
        let logged = false;
        if (props === undefined || props.global === undefined || props.global.token === undefined) return;
        if (props && props.global.token !== '') {
            logged = true;
        }
        return logged;
    }

    const getActiveTab = (history) => {
        setActiveTab(history.location.pathname);
    }

    const handleOpenChangeUserAction = (isOpen) => {
        setOpenUserAction(isOpen);
    };

    const handleOpenChangeUpdatePassword = () => {
        setVisiblePasswordChange(!visiblePasswordChange);
    }

    const renderMenu = () => {
        return (
            <Fragment>
                {
                    isLogged() ?
                        <Fragment>
                            <Link className="nav-link mita-text" to="/ket-qua"><li className={`nav-item ${activeTab == "/ket-qua" && "active"}`}>Trang chủ</li></Link>
                            <Link className="nav-link mita-text" to='.'><li className="nav-item" onClick={() => { logout() }}>Đăng xuất</li></Link>
                            <Popover
                                style={{ width: 300 }}
                                content={<Button className="mita-btn-round-main" onClick={handleOpenChangeUpdatePassword}>Đổi mật khẩu</Button>}
                                title={<label className='mita-text'>Tùy chọn</label>}
                                overlayInnerStyle={{ borderRadius: 20 }}
                                open={openUserAction}
                                onOpenChange={handleOpenChangeUserAction}>
                                <div className='mita-welcome-user'>
                                    <div className='mita-user-avatar'>
                                        <UserOutlined className="mita-text" />
                                    </div>
                                    <div className={`mita-text ${props.global.name?.length > 20 ? " mita-text-move" : ""}`}>
                                        Xin chào <b>{props.global.name ?? "quý khách hàng"}</b>
                                    </div>
                                </div>
                            </Popover>
                        </Fragment >
                        :
                        <Fragment>
                            {
                                menu && menu.map((m, index) => {
                                    return (
                                        <Link key={index} className="nav-link mita-text" to={m.toPage}><li className={`nav-item ${activeTab == m.toPage && "active"}`}>{m.name}</li></Link>
                                    )
                                })
                            }
                        </Fragment>
                }
            </Fragment>
        )
    }

    const handleOpenMenu = () => {
        setOpenMenu(!openMenu);
    }

    return (
        <Fragment>
            <header className="topbar">
                <nav className="mita-header navbar navbar-expand-lg">
                    <Link className="navbar-brand mita-brand" to=".">
                        <div className='mita-logo'>
                            <img src="/images/headlogo2.png" alt="homepage" />
                        </div>
                        <div className='mita-brandname'>
                            <div className='mita-brandname-title'>Multi Connection</div>
                            <div>&copy;{moment().format('YYYY')} Viettinlab</div>
                        </div>
                    </Link>
                    <button onClick={() => handleOpenMenu()} className="navbar-toggler" type="button" data-toggle="collapse" data-target=".mita-navbar-collapse" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Mở menu">
                        <span className="navbar-toggler-icon">
                            <i className="fas fa-bars mita-text text-white"></i>
                        </span>
                    </button>
                    <div className="collapse navbar-collapse mita-navbar-collapse">
                        <ul className="nav navbar-nav mita-navbar-nav">
                            {renderMenu()}
                        </ul>
                    </div>
                </nav>
            </header>
            <Modal
                className='mita-modal'
                title={<HeaderModal title="Thay đổi mật khẩu" onClose={() => { handleOpenChangeUpdatePassword(); }} />}
                open={visiblePasswordChange}
                onCancel={() => handleOpenChangeUpdatePassword()}
                onOk={() => { }}
                centered
                destroyOnClose
                width={900}
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { display: 'none' } }}
                footer=""
            >
                <ChangePassword handleOpenChangeUpdatePassword={handleOpenChangeUpdatePassword} />
            </Modal>
            <Drawer
                maskClosable
                title={
                    <HeaderModal title="Menu" onClose={() => handleOpenMenu()}></HeaderModal>
                }
                headerStyle={{ padding: 0 }}
                bodyStyle={{ padding: 0 }}
                placement="right"
                width={400}
                closable={false}
                onClose={() => handleOpenMenu()}
                open={openMenu}>
                <div className="mita-navbar-collapse">
                    <ul className="mita-navbar-nav">
                        {renderMenu()}
                    </ul>
                </div>
            </Drawer>
        </Fragment>
    );
})

const mapStateToProps = (state) => {
    return ({
        global: state.global,
        router: state.router,
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(Header);