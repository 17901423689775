import 'antd/dist/antd.css';
import vi from "date-fns/locale/vi";
import * as React from 'react';
import { registerLocale } from "react-datepicker";
import { Route, Switch } from 'react-router';
import './commons/css/animation.css';
import './commons/css/dashboard1.css';
// import './commons/css/jquery.toast.css';
import './commons/css/main.scss';
import './commons/css/morris.css';
import './commons/css/style.min.scss';
import Authenticated from './components/authenticated';
import Layout from './components/layout';
// import MDIContainer from './components/mdiContainer';
import './custom.scss';
// import Login from './screens/login/indexLogin';
import UnknowPage from './screens/unknowPage';
// import KetQuaXetNghiem from "./screens/patients/result";
import GuestResult from './screens/guest-examresult';
import CoporatorLogin from './screens/coporation-examresult/login';
import CoporatorResult from "./screens/coporation-examresult/result";
import CoporatorResultDetail from "./screens/coporation-examresult/detail";


registerLocale("vi", vi);
export default () => (
    <Layout >
        <Switch>
            <Route exact path='/' component={GuestResult} />
            <Route exact path='/tracuu' component={GuestResult} />
            <Route exact path='/login' component={CoporatorLogin} />
            <Authenticated exact path='/ket-qua' component={CoporatorResult} />
            <Authenticated exact path='/chi-tiet' component={CoporatorResultDetail} />
            <Authenticated component={UnknowPage} />
        </Switch>
    </Layout>
);
