import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'antd';
import * as ModalStore from '../../stores/modals';
import HeaderModal from '../../configureHIS/common/headerModal';

class ErrorMessageModal extends React.Component {
    componentDidMount() {
        document.addEventListener('keydown', (e) => {
            if (e.key === "Enter")
                this.onCancel();
        });
    }
    componentWillUnmount() {
        document.removeEventListener('keydown');
    }
    onCancel() {
        if (this.props.closeError())
            this.props.closeError();
    }
    render() {

        let { modal } = this.props;
        let { errContent } = modal;

        return (
            <Modal maskClosable={false} visible={modal ? modal.errModal : false} closable={false} zIndex={999999}
                cancelButtonProps={{ style: { display: 'none' } }}
                footer={null} style={{ zIndex: 999999 }} width={800}
                okButtonProps={{ style: { display: 'none' } }}>
                <div style={{
                    width: '100%', color: '#293555',
                    flexDirection: 'column', fontSize: '11px',
                    display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'auto'
                }}>
                    <div className='w-100'>
                        <HeaderModal title={`${modal ? modal.errHeader : ''}`} onClose={() => this.onCancel()} />
                    </div>
                    <div style={{
                        margin: 'auto',
                        flex: '1 1 auto',
                        overflow: 'auto',
                    }}>
                        <div style={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '16px',
                        }}
                            dangerouslySetInnerHTML={{ __html: `${errContent}` }}
                        >
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }} className='mt-2'>
                        <button className="btn btn-danger"
                            onClick={() => { this.onCancel() }}><i className="fas fa-sign-out-alt mr-1"/>Đóng</button>
                    </div>
                </div>
            </Modal>

        );
    }
}
const mapStateToProps = (state) => ({
    modal: state.modal,
});
export default connect(
    mapStateToProps,
    ModalStore.actions
)(ErrorMessageModal);