import React, { useEffect, useRef, useState, forwardRef, useCallback } from 'react';
import { Input, Button, Modal, Tooltip } from 'antd';
import pdfjs from "pdfjs-dist";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import * as Actions from '../../libs/actions';
import * as TestService from '../../services/lisService';
import * as ExamResultService from '../../services/examResultService';
import Ocean from "../../components/background";
import Notify, { NotifyStatus } from '../../components/notify';
import { isNotNull } from '../../libs/util';
import { ValidationCustom } from '../../commons/validationCustom';
import SimpleReactValidator from 'simple-react-validator';
import { Link } from 'react-router-dom';
import history from '../../stores/history';
import HeaderModal from '../../components/headerModal';
import ReCAPTCHA from "react-google-recaptcha";
import { CAPCHASITEKEY } from '../../configure/urlConfig';
import { QuestionCircleOutlined } from "@ant-design/icons";
import VideoHDSD from "../../commons/video/HDSD.mp4";
import PDFJS from '../../libs/pdf';

const SEARCH_PREFIX = "?q=";

export default forwardRef((props, ref) => {
    const _hiddenLink = useRef();
    const _notify = useRef();
    const _trackingCode = useRef();
    const recaptchaRef = useRef();
    const canvasRef = useRef();
    const [trackingCode, setTrackingCode] = useState('');
    const [listFile, setListFile] = useState([]);
    const [detail, setDetail] = useState({});
    const [visiblePreviewFile, setVisiblePreviewFile] = useState(false);
    const [fileUrlBlob, setFileUrlBlob] = useState('');

    const [pdf, setPDF] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [scale, setScale] = useState(1);
    const [numPages, setNumPages] = useState(0);
    const [isWindow, setIsWindow] = useState(true);
    const [, updateState] = React.useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const validator = useRef(new SimpleReactValidator(ValidationCustom));
    const [reCaptchaResponse, setReCaptchaResponse] = useState();
    const [isMobile, setIsMobile] = useState(false);
    const [dataBlob, setDataBlob] = useState();

    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
        setIsWindow(navigator.userAgent.indexOf('Windows') > -1);
        setIsMobile(navigator?.userAgentData?.mobile ?? false);
    }, []);

    useEffect(() => {

    }, [fileUrlBlob]);

    useEffect(() => {
        if (history.location?.search) {
            let trackCode = "";
            let searchQuery = history.location.search;
            const array = searchQuery.match(/\?q\=(\d*)/g);
            if (array != null) {
                trackCode = array[0].replace(SEARCH_PREFIX, "");
                setTrackingCode(trackCode);
            }
            getResult(trackCode);
        }
    }, [history])

    const loadPdfToCanvas = (url) => {
        const fetchPdf = async () => {
            if (!url) return;
            const loadingTask = pdfjs.getDocument(url);
            const pdfDoc = await loadingTask.promise;
            setPDF(pdfDoc);
            setNumPages(pdfDoc._pdfInfo.numPages);
            renderPage({ pdfDoc, pageNum: 1, scale: 1 });
        };
        fetchPdf();
    }

    const renderPage = useCallback(async ({ pdfDoc, pageNum, scale }) => {
        const page = await pdfDoc.getPage(pageNum);
        const ctx = canvasRef.current.getContext("2d");
        const viewport = page.getViewport({ scale });
        canvasRef.current.width = viewport.width;
        canvasRef.current.height = viewport.height;

        page.render({
            canvasContext: ctx,
            viewport: viewport,
        });
    }, []);

    const prevPage = () => {
        if (currentPage > 1) {
            renderPage({ pdfDoc: pdf, pageNum: currentPage - 1, scale });
            setCurrentPage(currentPage - 1);
        }
    };

    const nextPage = () => {
        if (currentPage < numPages) {
            renderPage({ pdfDoc: pdf, pageNum: currentPage + 1, scale });
            setCurrentPage(currentPage + 1);
        }
    };

    const zoomOut = () => {
        renderPage({ pdfDoc: pdf, pageNum: currentPage, scale: scale - 0.1 });
        setScale(scale - 0.1);
    };

    const zoomIn = () => {
        renderPage({ pdfDoc: pdf, pageNum: currentPage, scale: scale + 0.1 });
        setScale(scale + 0.1);
    };


    const getResult = (trackCode = trackingCode) => {
        if (trackCode == null || trackCode == "" || trackCode == undefined) {
            _notify && _notify.current.Show("Vui lòng nhập mã tra cứu", NotifyStatus.Warning);
            return;
        }
        Actions.setLoading(true);
        // const token = recaptchaRef.current.getValue();
        let data = {
            "trackingCode" : trackCode,
            // "captchaResponse" : token.toString()
        }
        ExamResultService.getResultByTrackingCode(data).then(result => {
            if (result.isSuccess) {
                setListFile(result.data ?? []);
                if (!isNotNull(result.data))
                    _notify && _notify.current.Show("Rất tiếc, chúng tôi chưa ghi nhận kết quả nào", NotifyStatus.Warning);
                else {
                    if (result.data.length == 1) onShowResultFile(result.data[0]);
                    _notify && _notify.current.Close();
                }
            }
            else {
                setListFile([]);
                _notify && _notify.current.Show(result.error.messageText, NotifyStatus.Error);
            }
        }).catch(err => {
            setListFile([]);
            if (err.error && err.error.messageText)
                _notify && _notify.current.Show(err.error.messageText, NotifyStatus.Error);
            else
                _notify && _notify.current.Show(err, NotifyStatus.Error);
        }).finally(() => {
            Actions.setLoading(false);
            recaptchaRef.current.reset();
        });
    }

    const viewResultPdf = (data) => {
        Actions.setLoading(true);
        TestService.DownLoadLisFile(data.uuid).then((response) => {
            response.blob().then((blob) => {
                setDataBlob(blob);
                let url = window.URL.createObjectURL(blob);
                setFileUrlBlob(url);
                setVisiblePreviewFile(true);
                if (!isWindow) {
                    loadPdfToCanvas(url);
                }
            })
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
        })
    }

    const downloadFile = () => {
        TestService.DownLoadLisFile(detail.uuid).then(result => {
            result.blob().then((blob) => {
                let url = window.URL.createObjectURL(blob);
                _hiddenLink.current.href = url;
                _hiddenLink.current.download = `${detail.title}`;
                _hiddenLink.current.click();
            })
        }).catch(err => {
        })
    }
    const onBtnTrackingClickHandle = () => {
        if (!validator.current.allValid()) {
            validator.current.showMessages();
            forceUpdate();
        } else {
            getResult();
        }
    }
    const onTrackingCodeKeyDownHandle = (e) => {
        if (e.keyCode == 13) onSubmitWithReCAPTCHA();
    }

    const onSubmitWithReCAPTCHA = () => {
        // const token = recaptchaRef.current.getValue();
        // if (!token) { // Check valid token ở BE
        //     _notify.current && _notify.current.Show("Vui lòng xác minh", NotifyStatus.Warning);
        //     return;
        // } else {
            onBtnTrackingClickHandle()
        // }
    }

    var verifyCallback = (response) => {
        setReCaptchaResponse(response);
    };

    const onShowResultFile = (data) => {
        setDetail(data);
        viewResultPdf(data);
    }

    return (
        <React.Fragment>
            <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={_hiddenLink} ></a>
            <Ocean />
            <div className='container-fluid h-100 d-flex flex-column mita-container'>
                <div className="mt-2">
                    <Notify ref={_notify} />
                </div>
                <div className='d-flex flex-column justify-content-center align-items-center w-100'>
                    <div className="text-center mrt-2">
                        <h1>Kết quả trực tuyến - Khách hàng cá nhân</h1>
                        <div className='mita-text'>Vui lòng nhập mã tra cứu để xem kết quả</div>
                    </div>
                    <div className='mrt-1 mita-input-check'>
                        <div className='w-100 mita-text d-flex'>
                            <label className='mr-auto mita-required'>Mã tra cứu</label>
                            <label>
                                <Tooltip title={<div className='mita-text fit-content'>Mã tra cứu được gửi qua SMS/Zalo... Vui lòng liên hệ phòng khám để biết thêm chi tiết</div>}
                                    placement='top' color='blue' overlayStyle={{ minWidth: "400px", maxWidth: "100vw", width: "500px" }}>
                                    <QuestionCircleOutlined />
                                </Tooltip>
                            </label>
                        </div>
                        <Input
                            ref={_trackingCode}
                            className='w-100 mita-input mita-text text-center'
                            placeholder='Nhập mã tra cứu'
                            value={trackingCode}
                            onClick={() => _trackingCode.current.select()}
                            onChange={(e) => { setTrackingCode(e.target.value); }}
                            onKeyUp={(e) => { onTrackingCodeKeyDownHandle(e); }}
                        ></Input>
                        {validator.current && validator.current.message('Mã tra cứu', trackingCode, 'required')}
                    </div>
                    {listFile && listFile.length > 0 && <div className='h-100 w-100 overflow-auto my-2 mx-auto'>
                        <div className='mita-text text-center mt-2'>Bấm vào file bên dưới để xem chi tiết</div>
                        <div className='mrt-1 mita-card-container'>
                            {listFile.map((row, index) =>
                                <div key={index} className='mita-card-item'
                                    onClick={(e) => {
                                        onShowResultFile(row);
                                    }}><i className='fas fa-file-pdf mr-2 mita-text'></i>{row.title}
                                </div>
                            )}
                        </div>
                    </div>}
                    {/* <div className='mrt-1 text-center'>
                        <ReCAPTCHA
                            style={{ display: "inline-block", position: "relative" }}
                            ref={recaptchaRef}
                            sitekey={CAPCHASITEKEY}
                            verifyCallback={(e) => verifyCallback(e)}
                        />
                    </div> */}
                    <div className='text-center'>
                        <Button className='mita-btn-round-main mrt-1'
                            onClick={() => { onBtnTrackingClickHandle(); }}
                        >Tra cứu</Button>
                    </div>
                    <div className='text-center pdb-1'>
                        <Link to='/login'>
                            <Button className='mita-btn-round-sub mrt-1'>
                                Bạn là khách hàng phòng khám
                            </Button>
                        </Link>
                    </div>
                </div>
                <Modal
                    destroyOnClose
                    className='mita-modal'
                    title={<HeaderModal title={
                        <Button className="mr-2 mita-btn-round-sub mita-btn-download" onClick={() => {
                            downloadFile()
                        }}><i className="fa fa-download"></i></Button>}
                        onClose={() => { setVisiblePreviewFile(false); }} />}
                    maskClosable={true}
                    width={1000}
                    height={'99%'}
                    open={visiblePreviewFile}
                    onCancel={() => { setVisiblePreviewFile(false); }}
                    footer={null}>
                    {
                        isMobile || !isWindow ?
                            <div className='mita-text text-center' style={{ overflowY: "auto" }}>
                                <PDFJS dataBlob={dataBlob}></PDFJS>
                                <Button className="mrt-1 mita-btn-round-main mb-2" onClick={() => {
                                    downloadFile()
                                }}><i className="fa fa-download mr-2"></i>Tải xuống file PDF</Button>
                                <div>Nếu không thể tải về hãy thử mở bằng trình duyệt bên ngoài</div>
                                <video style={{ width: "80%" }} autoplay="false" muted loop controls={false} src={VideoHDSD}></video>
                            </div>
                            :
                            <div className='h-100 w-100 d-flex flex-column'>
                                <div className="h-100 w-100">
                                    <embed hidden={!isWindow}
                                        src={`${fileUrlBlob}#toolbar=0`}
                                        className='h-100 w-100'
                                    />
                                    <canvas hidden={isWindow} ref={canvasRef} className='w-100 h-100' style={{ direction: 'ltr' }}></canvas>
                                </div>
                            </div>
                    }
                </Modal>
            </div>
        </React.Fragment>
    )
})