import React, { useRef, useState, useImperativeHandle, forwardRef } from 'react';

export const NotifyStatus = {
    Success: 1,
    Error: 3,
    Warning: 2
}

export default forwardRef((props, ref) => {
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState(1);
    const [duration, setDuration] = useState(0);
    useImperativeHandle(ref, () => ({
        Show: (mes, stus, timeout) => {
            setMessage(mes)
            setStatus(stus)
            setShow(true)
            if (timeout) {
                setDuration(timeout);
                setTimeout(() => {
                    setShow(false)
                }, timeout*1000);
            }
        },
        Close: () => {
            setShow(false)
        }
    }));
    const GetStatus = (tus) => {
        if (tus == NotifyStatus.Error)
            return 'danger'
        if (tus == NotifyStatus.Warning)
            return 'warning'
        return 'success'
    }
    return <React.Fragment>
        {show == true ? <div className={`${props.className ?? ""} mita-notification alert alert-${GetStatus(status)} alert-dismissible fade show`}
            style={{ whiteSpace: "pre-line", animation: duration > 0 && `fadeInTop ${duration}s ease alternate`}}
            role="alert">
            {message}
            <button type="button" className="close" data-dismiss="alert" aria-label="Close"
                onClick={() => {
                    setShow(false)
                }}
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div> :
            null
        }
    </React.Fragment>
})

export function Success(message = "") {
    return <div className="mita-notification alert alert-success alert-dismissible fade show" role="alert" style={{whiteSpace: "pre-line"}}>
        {message}
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
}

export function Warning(message = "") {
    return <div className="mita-notification alert alert-warning alert-dismissible fade show" role="alert" style={{whiteSpace: "pre-line"}}>
        {message}
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
}

export function Error(message = "") {
    return <div className="mita-notification alert alert-danger alert-dismissible fade show" role="alert" style={{whiteSpace: "pre-line"}}>
        {message}
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
}
