import * as ApiCaller from '../libs/httpRequests';
import moment from 'moment';

const prefix = 'common';
export function GetListDoctor(pageNumber = 1, pageSize = 200) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/danhsachbs?pageNumber=${pageNumber}&pageSize=${pageSize}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetListDoctorTT(pageNumber = 1, pageSize = 200) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListDoctorsThuThuat?pageNumber=${pageNumber}&pageSize=${pageSize}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function SendResultForUser(mabn, maql) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/SendResultForUser?mabn=${mabn}&maql=${maql}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetDataFromLis(maql, sid) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetDataFromLis/${maql}?sid=${sid}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function GetListPatient(key = '', pageNumber = 1, pageSize = 10, dateFrom = moment().format('YYYY/MM/DD'), dateTo = moment().format('YYYY/MM/DD')) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/dsbn?key=${key}&pageNumber=${pageNumber}&pageSize=${pageSize}&dateFrom=${dateFrom}&dateTo=${dateTo}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DanhSachBenhNhanDaDangKy(key = '', pageNumber = 1, pageSize = 10, dateFrom = moment().format('YYYY/MM/DD'), dateTo = moment().format('YYYY/MM/DD')) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachBenhNhanDaDangKy?key=${key}&pageNumber=${pageNumber}&pageSize=${pageSize}&dateFrom=${dateFrom}&dateTo=${dateTo}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetDepartment() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/khoakham`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function GetListDiagnostic(input = '', pageNumber = 1, pageSize = 10) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/loaibenh?input=${input}&pageNumber=${pageNumber}&pageSize=${pageSize}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetListNhanTu() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/dsnhantu`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetListDenTu() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/dsdentu`).then(respone => {

            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetPatientDetail(mabn) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DetailPatient/${mabn}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function ListGoHospital(mabn, pageNumber = 1, pageSize = 10) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/dsvaovien?mabn=${mabn}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function searchPatient(key, pageNumber = 1, pageSize = 10) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/dsbnsearch?key=${key}&pageNumber=${pageNumber}&pageSize=${pageSize}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
}


export function NoiChuyenDen(makhoa, maql) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/noichuyenden?makhoa=${makhoa}&maql=${maql}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DSNoiChuyenDen(makhoa) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/dsnoichuyenden?makhoa=${makhoa}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DanhSachKetQua() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/dsketqua`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DanhSachTinhTrang(xuatvien = false) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/dstinhtrang?xuatvien=${xuatvien}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function LoaiBenhVien() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/loaibenhvien`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function BenhVienChuyenDen() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/benhvienchuyenden`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DeleteOneDirect(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`RegisterMedical/DeleteOneDirect`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ListXuTri() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/dsxutri`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DanhSachLoaiPhong(makp) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachLoaiPhong?makp=${makp}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DanhSachPhong(makp, loai) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachPhong?makp=${makp}&loai=${loai}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DanhSachGiuong(loai) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachGiuong?loai=${loai}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DanhSachKhoaKham() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/dskhoakham`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DanhSachNhomVP() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/dsnhomvp`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function DanhMucVung() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/danhmucvung`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function DanhMucThanhPho(key, page, size) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/danhmucthanhpho?key=${key}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function DanhMucQuanHuyen(matt, key, page, size) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/danhmucquanhuyen?matt=${matt}&key=${key}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function DanhMucThanhThi() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/danhmuctt`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function DanhMucHuyen(key) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/danhmucquan?key=${key}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function DanhMucPhuongXa(maquan, key, page, size) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/danhmucphuongxa?maquan=${maquan}&key=${key}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function updateCity(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/capnhatdanhmuc`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function deleteCity(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/deldanhmuctp`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ListPatientRelative(mabn) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/ListPatientRelative?mabn=${mabn}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetServicesHaveBeenSelected(maql, ngay) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetServicesHaveBeenSelected?maql=${maql}&ngay=${ngay}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function STTKhamBenh(makp, ngay) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/sttkhambenh?makp=${makp}&ngaykham=${ngay}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            return reject(err)
        })
    });
};

export function templateName() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/template`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            return reject(err)
        })
    });
};

export function UpdateHienThiKhoa(makhoa, hienthi) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/UpdateHienThiKhoa?makhoa=${makhoa}&hienthi=${hienthi}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            return reject(err)
        })
    });
};

export function UpdateHienThiPhong(idphong, hienthi) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/UpdateHienThiPhong?idphong=${idphong}&hienthi=${hienthi}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            return reject(err)
        })
    });
};

export function GetListCollaborators(pageNumber, pageSize, getAll, group = "all") {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/danhsachcongtacvien?pageNumber=${pageNumber}&pageSize=${pageSize}&getAll=${getAll}&group=${group}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};


export function resetMatKhau(mabn) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPut(`${prefix}/resetmatkhau?mabn=${mabn}`,).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function SearchPatientByIdKhaiBao(query) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`RegisterMedical/SearchPatientByIdKhaiBao?query=${query}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetLisResultToEdit(maql, sid) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetLisResultToEdit?maql=${maql}&sid=${sid}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function UpdateResultTest(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/UpdateResultTest`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};