import * as ApiCaller from '../libs/httpRequests';

const prefix = 'Lis';
export function DanhSachBenhNhan(dateFrom, dateTo, key = '', page = 1, size = 10) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/danhsachbenhnhan?dateFrom=${dateFrom}&dateTo=${dateTo}&key=${key}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DanhSachBenhNhanDichVu(dateFrom, dateTo, type, key = '', page = 1, size = 10) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachBenhNhanDichVu?dateFrom=${dateFrom}&dateTo=${dateTo}&type=${type}&key=${key}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DanhSachThuThuatBenhNhanIncludeInfo(dateFrom, dateTo, type, key = '', page = 1, size = 10, trangthai = '') {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachThuThuatBenhNhanIncludeInfo?dateFrom=${dateFrom}&dateTo=${dateTo}&type=${type}&key=${key}&page=${page}&size=${size}&trangthai=${trangthai}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ThongTinKetQua(maql, sid, noitru = false) {
    if (sid === undefined)
        sid = '';
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/ThongTinKetQua?maql=${maql}&sid=${sid}&noitru=${noitru}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ThongTinKetQuaTheoBenhTrinh(idbenhtrinh) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/ThongTinKetQuaTheoBenhTrinh?idbenhtrinh=${idbenhtrinh}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ResendDataToLis(maql, ngay, sid) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/ResendDataToLis?maql=${maql}&ngay=${ngay}&sid=${sid}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getListErrorTests(dateFrom, dateTo) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/getListErrorTests?from=${dateFrom}&to=${dateTo}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DownLoadLisFile(uuid) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/downloadfile?uuid=${uuid}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};