import React, { Fragment, forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { connect } from 'react-redux';
import * as Actions from '../../libs/actions';
import { NotifyStatus } from '../../components/notify';
import * as CoporatorService from "../../services/coporatorService";
import pdfjs from "pdfjs-dist";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { Button, Modal } from 'antd';
import HeaderModal from '../../components/headerModal';
import VideoHDSD from "../../commons/video/HDSD.mp4";
import PDFJS from '../../libs/pdf';

const ViewFile = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        onSearch: () => onSearch(),
        onShowFile: () => viewResultPdf(detail),
    }));

    const _hiddenLink = useRef();
    const canvasRef = useRef();
    const [, updateState] = React.useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const [fileUrlBlob, setFileUrlBlob] = useState('');
    const [visiblePreviewFile, setVisiblePreviewFile] = useState(false);
    const [isWindow, setIsWindow] = useState(true);
    const [detail, setDetail] = useState({});
    const [pdf, setPDF] = useState(null);
    const [numPages, setNumPages] = useState(0);
    const [isMobile, setIsMobile] = useState(false);
    const [dataBlob, setDataBlob] = useState();

    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
        setIsWindow(navigator.userAgent.indexOf('Windows') > -1);
        setIsMobile(navigator?.userAgentData?.mobile ?? false);
    }, []);

    useEffect(() => {
        onSearch();
    }, [props.patientCode, props.managementId, props.groupId, props.dateIn]);

    const [listFile, setListFile] = useState([]);

    const onSearch = (patientCode = props.patientCode, managementId = props.managementId, groupId = props.groupId, dateIn = props.dateIn) => {
        Actions.setLoading(true);
        CoporatorService.CoporatorListFilePatient(patientCode, managementId, groupId, dateIn).then(result => {
            if (result.isSuccess) {
                let data = result.data;
                setListFile(data);
                if (data && data.length == 1)
                    onPreviewFile(data[0]);
            } else {
                props.showNotification && props.showNotification(result.error.messageText, NotifyStatus.Error);
            }
        }).catch(err => {
            props.showNotification && props.showNotification(err.error.messageText, NotifyStatus.Error);
        }).finally(() => {
            Actions.setLoading(false);
        })
    }

    const onSelectFile = (file) => {
        setListFile(listFile.map((x) => ({ ...x, selected: file.uuid == x.uuid })));
        setDetail(file);
    }

    const onPreviewFile = (file) => {
        setDetail(file);
        viewResultPdf(file);
    }

    const viewResultPdf = (data) => {
        if (data.uuid) {
            Actions.setLoading(true);
            CoporatorService.DownloadLisFile(data.uuid)
                .then((response) => {
                    response.blob().then((blob) => {
                        setDataBlob(blob);
                        let url = window.URL.createObjectURL(blob);
                        setFileUrlBlob(url);
                        setVisiblePreviewFile(true);
                        if (!isWindow) {
                            loadPdfToCanvas(url);
                        }
                    })
                    Actions.setLoading(false);
                }).catch(err => {
                    Actions.setLoading(false);
                })
        }
    }

    const loadPdfToCanvas = (url) => {
        const fetchPdf = async () => {
            if (!url) return;
            const loadingTask = pdfjs.getDocument(url);
            const pdfDoc = await loadingTask.promise;
            setPDF(pdfDoc);
            setNumPages(pdfDoc._pdfInfo.numPages);
            renderPage({ pdfDoc, pageNum: 1, scale: 1 });
        };
        fetchPdf();
    }

    const renderPage = useCallback(async ({ pdfDoc, pageNum, scale }) => {
        const page = await pdfDoc.getPage(pageNum);
        canvasRef.current.style.display = "block";
        const ctx = canvasRef.current.getContext("2d");
        const viewport = page.getViewport(scale);
        var resolution = isMobile ? 1.5 : 1;
        canvasRef.current.width = resolution * viewport.width;
        canvasRef.current.height = resolution * viewport.height;
        page.render({
            canvasContext: ctx,
            viewport: viewport,
            transform: [resolution, 0, 0, resolution, 0, 0],
        });
    }, []);

    const downloadFile = () => {
        CoporatorService.DownloadLisFile(detail.uuid).then(result => {
            result.blob().then((blob) => {
                let url = window.URL.createObjectURL(blob);
                _hiddenLink.current.href = url;
                let fileName = `${detail.title}`;
                _hiddenLink.current.download = fileName;
                _hiddenLink.current.click();
            })
        }).catch(err => {
        })
    }

    return (
        <Fragment>
            <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={_hiddenLink} ></a>
            <ul style={{ listStyle: "none", paddingInlineStart: 0 }}>
                {
                    listFile && listFile.map((file, index) => {
                        return (
                            <li className={`mita-text mita-hover${file.selected ? " mita-focus" : ""} mt-2`} onClick={() => onSelectFile(file)} onDoubleClick={() => { onPreviewFile(file) }}>
                                {file.title}
                            </li>
                        )
                    })
                }
            </ul>
            <Modal
                className='mita-modal'
                title={<HeaderModal title={
                    <Button className="mr-2 mita-btn-round-sub mita-btn-download" onClick={() => {
                        downloadFile()
                    }}><i className="fa fa-download"></i></Button>}
                    onClose={() => { setVisiblePreviewFile(false); 
                        listFile && listFile.length == 1 && props.closeModal && props.closeModal(); }} />}
                maskClosable={true}
                width={1000}
                height={'99%'}
                open={visiblePreviewFile}
                onCancel={() => { setVisiblePreviewFile(false); }}
                footer={null}>
                {
                    isMobile || !isWindow ?
                        <Fragment>
                            <div className='mita-text text-center'>
                                <PDFJS dataBlob={dataBlob}></PDFJS>
                                <Button className="mrt-1 mita-btn-round-main mb-2" onClick={() => {
                                    downloadFile()
                                }}><i className="fa fa-download mr-2"></i>Tải xuống file PDF</Button>
                                <div>Nếu không thể tải về hãy thử mở bằng trình duyệt bên ngoài</div>
                                <video style={{ width: "80%" }} autoplay="false" muted loop controls={false} src={VideoHDSD}></video>
                            </div>
                        </Fragment>
                        :
                        <div className='h-100 w-100 d-flex flex-column'>
                            <div className="h-100 w-100">
                                <embed hidden={!isWindow}
                                    src={`${fileUrlBlob}#toolbar=0`}
                                    className='h-100 w-100'
                                />
                                <canvas hidden={isWindow} ref={canvasRef} className='w-100 h-100' style={{ direction: 'ltr' }}></canvas>
                            </div>
                        </div>
                }
            </Modal>
        </Fragment>
    );
})

const mapStateToProps = (state) => {
    return ({
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(ViewFile);