import * as React from 'react';
import './main.css';
import LoadingPage from './loadingPage';

const Loading = (props) => {
    if (props.show) {
        if (props.inside) {
            return (
                <div className="lds-wrapper-fit-parent">
                    <LoadingPage />
                </div>
            )
        } else {
            return (
                <div className="lds-wrapper">
                    <LoadingPage />
                </div>
            )
        }
    } else {
        return (<div style={{ display: 'none' }}></div>);
    }
}

export default (Loading);
