import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle, useCallback, Fragment } from 'react';
import { Input, Button } from 'antd';
import { connect } from 'react-redux';
import * as AuthServices from '../../services/authServices';
import { ValidationCustom } from '../../commons/validationCustom';
import SimpleReactValidator from 'simple-react-validator';
import Notify, { NotifyStatus } from '../../components/notify';
import { isNotNull } from '../../libs/util';
import { logout } from '../../libs/actions';

const CoporationResult = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
    }));

    useEffect(() => {
    }, []);

    const _notify = useRef();
    const _oldPassword = useRef();
    const _password = useRef();
    const _passwordVer = useRef();
    const [, updateState] = React.useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const validator = useRef(new SimpleReactValidator(ValidationCustom));
    const [oldPassword, setOldPassword] = useState();
    const [password, setPassword] = useState();
    const [passwordVer, setPasswordVer] = useState();
    const [showPassword, setShowPassword] = useState(false);
    const [showOldPassword, setShowOldPassword] = useState(false);

    const handlePressEnter = (e) => {
        if (e.key == "Enter") {
            if (isNotNull(oldPassword) && isNotNull(password) && isNotNull(passwordVer))
                onChangePassword();
            else if (!isNotNull(oldPassword)) _oldPassword.current.focus();
            else if (!isNotNull(password)) _password.current.focus();
            else if (!isNotNull(passwordVer)) _passwordVer.current.focus();
        }
    }

    const onChangePassword = () => {
        if (!validator.current.allValid()) {
            validator.current.showMessages();
            forceUpdate();
            return;
        }
        _notify.current.Close();
        const data = {
            oldPassword,
            password
        }
        AuthServices.coporatorChangePassword(data).then((res) => {
            if (password != passwordVer) {
                _notify && _notify.current.Show("Mật khẩu xác nhận lại không khớp", NotifyStatus.Warning);
                return;
            }
            if (password == oldPassword) {
                _notify && _notify.current.Show("Vui lòng nhập mật khẩu mới khác mật khẩu cũ", NotifyStatus.Warning);
                return;
            }
            if (res.isSuccess === true) {
                _notify && _notify.current.Show("Đã thay đổi mật khẩu, vui lòng đăng nhập lại", NotifyStatus.Success);
                disableFunction();
                setTimeout(() => {
                    logout();
                }, 2000);
            } else {
                _notify && _notify.current.Show(res.error?.extensionData ?? "Không thể thực hiện thao tác", NotifyStatus.Error);
            }
        }).catch(err => {
            _notify && _notify.current.Show(err.error?.extensionData, NotifyStatus.Error);
        })
    }

    const [disableChangePassword, setDisableChangePassword] = useState(false);
    const disableFunction = () => {
        setDisableChangePassword(true);
        forceUpdate();
    }

    return (
        <React.Fragment>
            <div className="mt-2">
                <Notify ref={_notify} />
            </div>
            {
                !disableChangePassword ?
                    <Fragment>
                        <div className='d-flex flex-column justify-content-center align-items-center w-100'>
                            <div className='mita-input-check'>
                                <div className='mita-text mita-required'>Mật khẩu cũ</div>
                                <i className={showOldPassword ? "far fa-eye showPassword" : "far fa-eye-slash showPassword"}
                                    onMouseDown={() => setShowOldPassword(true)}
                                    onPointerDown={() => setShowOldPassword(true)}
                                    onMouseUp={() => setShowOldPassword(false) }
                                    onPointerUp={() => setShowOldPassword(false) } />
                                <Input
                                    ref={_oldPassword}
                                    className='w-100 mita-input mita-text'
                                    placeholder='Nhập mật khẩu cũ'
                                    value={oldPassword}
                                    type={showOldPassword ? "text" : "password"}
                                    onChange={(e) => { setOldPassword(e.target.value); }}
                                    onKeyDown={(e) => { handlePressEnter(e) }}
                                ></Input>
                                {validator.current && validator.current.message('Mật khẩu cũ', oldPassword, 'required')}
                            </div>
                            <div className='mt-4 mita-input-check'>
                                <div className='mita-text mita-required'>Mật khẩu mới</div>
                                <i className={showPassword ? "far fa-eye showPassword" : "far fa-eye-slash showPassword"}
                                    onMouseDown={() => setShowPassword(true)}
                                    onPointerDown={() => setShowPassword(true)}
                                    onMouseUp={() => setShowPassword(false) }
                                    onPointerUp={() => setShowPassword(false) } />
                                <Input
                                    ref={_password}
                                    className='w-100 mita-input mita-text'
                                    placeholder='Nhập mật khẩu mới'
                                    value={password}
                                    type={showPassword ? "text" : "password"}
                                    onChange={(e) => { setPassword(e.target.value); }}
                                    onKeyDown={(e) => { handlePressEnter(e); }}
                                ></Input>
                                {validator.current && validator.current.message('Mật khẩu mới', password, 'required')}
                            </div>
                            <div className='mt-4 mita-input-check'>
                                <div className='mita-text mita-required'>Xác nhận mật khẩu mới</div>
                                <i className={showPassword ? "far fa-eye showPassword" : "far fa-eye-slash showPassword"}
                                    onMouseDown={() => setShowPassword(true)}
                                    onPointerDown={() => setShowPassword(true)}
                                    onMouseUp={() => setShowPassword(false) }
                                    onPointerUp={() => setShowPassword(false) } />
                                <Input
                                    ref={_passwordVer}
                                    className='w-100 mita-input mita-text'
                                    placeholder='Nhập lại mật khẩu mới'
                                    value={passwordVer}
                                    type={showPassword ? "text" : "password"}
                                    onChange={(e) => { setPasswordVer(e.target.value); }}
                                    onKeyDown={(e) => { handlePressEnter(e); }}
                                ></Input>
                                {validator.current && validator.current.message('Xác nhận mật khẩu mới', passwordVer, 'required')}
                            </div>
                        </div>
                        <div className='text-right mrt-1'>
                            <Button className='mita-btn-round-sub' onClick={() => { props.handleOpenChangeUpdatePassword(); }}>Hủy</Button>
                            <Button className='mita-btn-round-main ml-2' onClick={() => onChangePassword()}>Đồng ý</Button>
                        </div>
                    </Fragment>
                    :
                    <div className='text-right mrt-1'>
                        <Button className='mita-btn-round-main ml-2' onClick={() => props.handleOpenChangeUpdatePassword()}>Đồng ý</Button>
                    </div>
            }
        </React.Fragment>
    )
})

function mapStateToProps(state, props) {
    return {
        global: state.global
    };
}

export default connect(mapStateToProps)(CoporationResult)