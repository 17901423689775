import { store } from '../stores';

export function openMessageDialog(errorHeader, errContent) {
    store.dispatch({ type: 'MODAL_SET_LOADING_MODAL', status: false });
    store.dispatch({ type: 'MODAL_OPEN_ERROR_MODAL', errHeader: errorHeader, errContent: errContent });
}

export function logout() {
    localStorage.setItem('token', '');
    localStorage.setItem('name', '');
    localStorage.setItem('groupid', '');
    localStorage.setItem('title', '');
    localStorage.setItem('userID', '');
    sessionStorage.setItem("dateFrom", "");
    sessionStorage.setItem("dateTo", "");
    store.dispatch({ type: 'USER_SET_DATA', data: { token: '', name: '' } });
}

export function openPrintDialog(func, params) {

    store.dispatch({ type: 'MODAL_OPEN_PRINT_MODAL', func: func, params: params });

}


export function closePrintDialog() {
    store.dispatch({ type: 'MODAL_CLOSE_PRINT_MODAL' });
}

export function setLoading(state) {
    store.dispatch({ type: 'MODAL_SET_LOADING_MODAL', status: state });
}
export function clearTabs() {
    store.dispatch({ type: 'TAB_CLEAR'});
}
// export function DoPrint(promiseFunction) {
//     promiseFunction.then(repsone => {
//         repsone.blob().then((blob) => {
//             let url = window.URL.createObjectURL(blob);
//             store.dispatch({ type: 'MODAL_OPEN_PRINT_MODAL', file: url });
//         })
//     }).catch(ex => {
//         store.dispatch({ type: 'MODAL_OPEN_ERROR_MODAL', errHeader: "error", errContent: ex.toString()});
//     })

// }

export function DoPrint(file) {
    store.dispatch({ type: 'MODAL_OPEN_PRINT_MODAL', file: file });           
}

export function setDataBarCode(data) {
    store.dispatch({ type: 'SET_DATA_BARCODE', data: data });
}

export function ResetBarCode(data) {
    store.dispatch({ type: 'RESET_BARCODE', data: data });
}

export function ResetListPhieuLinhThuoc(data) {
    store.dispatch({ type: 'RESET_LIST_PHIEULINHTHUOC', data: data });
}

export function ReloadPhieuThuoc(data) {
    store.dispatch({ type: 'RELOAD_PHIEUTHUOC', data: data });
}

export function ResetListPhieuTraThuoc(data) {
    store.dispatch({ type: 'RESET_LIST_PHIEUTRATHUOC', data: data });
}

export function EditPhieuTraThuoc(data) {
    // { isEdit: true, idphieutra: idphieutra
    store.dispatch({ type: 'EDIT_PHIEUTRATHUOC', data: data });
}