import { Collapse, Table, Tabs } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import Result from '../../screens/lis/component/result';
import ThuThuat from '../../screens/exam/examination/thuthuatResult';
import * as PatientService from '../../services/patientService';
import * as ChiDinhService from '../../services/chiDinhService';
import CDHA from './cdha';

const { Panel } = Collapse;
const { TabPane } = Tabs
export default class HistoryDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            historyInfo: null,
            ngayhenkham: null,
            chuandoan: '',
            ketluan: '',
        }
    };

    componentWillUnmount() {
    }

    componentDidMount() {

        PatientService.getHistoryDetails(this.props.selectedTran.maql, moment(this.props.selectedTran.time).format('YYYY-MM-DD'),
            this.props.mabn)
            .then((result) => {
                if (result.isSuccess === true) {
                    this.setState({ historyInfo: result.item })
                }
            })
        this.ThongTinKetLuan();
    }

    ThongTinKetLuan() {
        ChiDinhService.ThongTinKetLuanHenKham(this.props.mabn, this.props.selectedTran.maql).then(result => {
            if (result.isSuccess) {
                if (moment(result.item.ngaygiohen).year() !== 1) {
                    this.setState({
                        ngayhenkham: moment(result.item.ngaygiohen),
                    })
                }
                this.setState({
                    chuandoan: result.item.chuandoan,
                    ketluan: result.item.ketluan,
                })
            }
        })
    }

    render() {
        let { historyInfo } = this.state;
        const columns = [
            {
                title: 'TT',
                dataIndex: 'index',
                key: 'index',
                render: (text, record, index) => Number(index) + 1,
            },
            {
                title: 'Tên thuốc',
                dataIndex: 'name',
                key: 'ten',
            },
            {
                title: 'Mã thuốc',
                dataIndex: 'code',
                key: 'code',
            },
            {
                title: 'Số lượng',
                dataIndex: 'amount',
                key: 'amount',
            },
        ]
        return (
            <React.Fragment>
                {historyInfo &&
                    <Tabs animated={true}>
                        <TabPane tab={"Khám bệnh"} key="1">
                            <div style={{ display: "flex" }}>
                                <span>Chẩn đoán:{'\u00A0'}</span>
                                <strong>{this.state.chuandoan}</strong>
                            </div>
                            <div style={{ display: "flex" }}>
                                <span>Kết luận:{'\u00A0'}</span>
                                <strong>{this.state.ketluan}</strong>
                            </div>
                            <div style={{ display: "flex" }}>
                                <span>Ngày hẹn tái khám:{'\u00A0'}</span>
                                <strong>{this.state.ngayhenkham !== null ? moment(this.state.ngayhenkham).format('DD/MM/YYYY HH:mm') : ''}</strong>
                            </div>
                        </TabPane>
                        {historyInfo && historyInfo.medicineHistory && historyInfo.medicineHistory.isHasHistory === true &&
                            <TabPane tab={"Đơn thuốc"} key="2">
                                <div style={{ display: 'flex' }}>
                                    <div style={{ flex: 1, padding: 10 }} >
                                        <div className='ccCenter'>
                                            <h4>Đơn thuốc chỉ định</h4>
                                        </div>
                                        <Table pagination={false}
                                            dataSource={historyInfo.medicineHistory.issuedByDoctor} columns={columns} rowKey={(e, index) => index} />
                                    </div>
                                    <div style={{ flex: 1, padding: 10 }} >
                                        <div className='ccCenter'>
                                            <h4>Đơn thuốc đã bán</h4>
                                        </div>
                                        {historyInfo.medicineHistory.patientBoughtList.map((record, index) => {
                                            return (
                                                <div style={{ marginBottom: 10 }}>
                                                    {historyInfo.medicineHistory.patientBoughtList.length > 1 &&
                                                        <div>ID: {record.idMedicine}</div>
                                                    }
                                                    <Table pagination={false}
                                                        dataSource={record.patientBought} columns={columns} rowKey={(e, index) => index} />
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </TabPane>
                        }
                        {historyInfo.utraSoundResult && historyInfo.utraSoundResult.isHasHistory === true &&
                            <TabPane tab={"Chẩn đoán hình ảnh"} key="3">
                                <div style={{ display: 'flex' }}>
                                    <Collapse style={{ width: '100%' }} defaultActiveKey={['0']}>
                                        {historyInfo.utraSoundResult.result.map((record, index) => {
                                            return (
                                                <Panel className="site-collapse-custom-collapse" header={record.tenloai} key={index}>
                                                    <CDHA key={record.idchidinh} item={record}></CDHA>
                                                </Panel>
                                            )
                                        })}
                                    </Collapse>

                                </div>
                            </TabPane>
                        }


                        {this.props.selectedTran.maql && this.props.selectedTran.time &&
                            <TabPane tab={"Xét nghiệm"} key="4">
                                < Result
                                    history={true}
                                    maql={this.props.selectedTran.maql}
                                    ngay={this.props.selectedTran.time} />
                            </TabPane>
                        }


                        {this.props.selectedTran.maql !== undefined && this.props.selectedTran.time !== undefined &&
                            <TabPane tab={"Thủ thuật"} key="5">
                                <ThuThuat
                                    disabledSave={false}
                                    managerId={this.props.selectedTran.maql}
                                    dateIn={moment(this.props.selectedTran.time).format("YYYY/MM/DD HH:mm")}
                                    code={this.props.mabn}
                                    history={true}
                                />
                            </TabPane>
                        }


                    </Tabs>

                }
            </React.Fragment>
        )
    }
}
