import React, { useCallback, forwardRef, useEffect, useImperativeHandle, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { NotifyStatus } from '../../components/notify';
import MitaTable from "../../components/mita-result/table";
import { Icons } from '../../libs/icons';
import { Tooltip } from 'antd';

const CoporatorResultDetail = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
    }));

    useEffect(() => {
        setData(props.data);
        setColumns(props.columns);
        setGroupOn(props.groupOn);
        setListSub(props.listSub); // Name of list of parent's item
        forceUpdate();
    }, [props.data, props.columns, props.groupOn, props.listSub]);

    const [, updateState] = React.useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    const [data, setData] = useState(props.data ?? []);
    const [columns, setColumns] = useState(props.columns ?? []);
    const [groupOn, setGroupOn] = useState(props.groupOn);
    const [listSub, setListSub] = useState(props.listSub);

    const handlePrint = () => {
        if (props.handlePrint) props.handlePrint();
        else props.showNotification && props.showNotification("Không tìm thấy function", NotifyStatus.Error);
    }

    const handleExport = () => {
        if (props.handleExport) props.handleExport();
        else props.showNotification && props.showNotification("Không tìm thấy function", NotifyStatus.Error);
    }

    const handleView = () => {
        if (props.handleView) props.handleView();
        else props.showNotification && props.showNotification("Không tìm thấy function", NotifyStatus.Error);
    }

    return (
        <div className='mrt-1'>
            {
                props.data && props.data.length > 0 &&
                <div className='mita-button-group mita-right'>
                    {/* <button className='mita-btn-square' onClick={() => handlePrint()}>{Icons.Printer}</button>
                    <button className='mita-btn-square' onClick={() => handleExport()}>{Icons.Download}</button> */}
                    <Tooltip overlayStyle={{ minWidth: "fit-content" }} title={<div className='mita-text fit-content'>Bấm vào để xem file đính kèm</div>} placement='leftBottom'>
                        <button className='mita-btn-square' onClick={() => handleView()}><i className="fa fa-download mita-text p-2"></i></button>
                    </Tooltip>
                </div>
            }
            <div className='mt-4 pdb-1'>
                <MitaTable columns={columns ?? []} data={data ?? []} groupOn={groupOn} listSub={listSub}/>
            </div>
        </div>
    );
})

const mapStateToProps = (state) => {
    return ({
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(CoporatorResultDetail);