import * as ApiCaller from '../libs/httpRequests';
import { store } from '../stores';
const prefix = 'PatientPortal';

export function logout() {
    localStorage.setItem('token', '');
    localStorage.setItem('name', '');
    localStorage.setItem('title', '');
    localStorage.setItem('userID', '');
    store.dispatch({ type: 'USER_SET_DATA', data: { token: '', name: '', role: '', title: '' } });
}

export function checkAuth() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/check`, true)
            .then(objRespone => {
                if (objRespone.isSuccess === true) {
                } else {
                    store.dispatch({ type: 'USER_SET_DATA', data: { token: '', name: '', role: '', title: '' } });
                }
                return resolve(objRespone)
            }).catch(err => {
                store.dispatch({ type: 'USER_SET_DATA', data: { token: '', name: '', role: '', title: '' } });
                return reject(err)
            });
    });
}

export function patientLogin(username, password) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost('thirdparty/patientsignin', {
            userName: username,
            password: password,
        }, false)
            .then(objRespone => {
                if (objRespone.isSuccess === true) {
                    let token = '';
                    let name = '';
                    let userID = '';
                    if (objRespone.token) {
                        token = objRespone.token
                        name = objRespone.name || ''
                        userID = objRespone.userID || ''
                    }
                    localStorage.setItem('token', token);
                    localStorage.setItem('name', name);
                    localStorage.setItem('userID', userID);
                    store.dispatch({
                        type: 'USER_SET_DATA', data: {
                            token: objRespone.token, name: name, userID:userID,
                            groupid: objRespone.groupid
                        }
                    });
                } else {
                    // store.dispatch({
                    //     type: 'MODAL_OPEN_ERROR_MODAL', errHeader: "Đăng nhập thất bại",
                    //     errContent: objRespone.err.msgString
                    // });
                }
                return resolve(objRespone)

            }).catch(err => {
                // store.dispatch({
                //     type: 'MODAL_OPEN_ERROR_MODAL', errHeader: "Đăng nhập thất bại",
                //     errContent: JSON.stringify(err)
                // });
                return reject(err)
            });
    });
}

export function coporatorLogin(username, password) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/LoginPortal`, {
            userName: username,
            password: password,
        }, false)
            .then(objRespone => {
                if (objRespone.isSuccess === true) {
                    let token = '';
                    let name = '';
                    let userID = '';
                    if (objRespone.data.token) {
                        token = objRespone.data.token
                        name = objRespone.data.name || ''
                        userID = objRespone.data.userID || ''
                    }
                    localStorage.setItem('token', token);
                    localStorage.setItem('name', name);
                    localStorage.setItem('userID', userID);
                    store.dispatch({
                        type: 'USER_SET_DATA', data: {
                            token: objRespone.data.token, name: name, userID: userID,
                        }
                    });
                } else {
                }
                return resolve(objRespone)

            }).catch(err => {
                return reject(err)
            });
    });
}

export function coporatorChangePassword(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/ChangePasswordPortal`, data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};