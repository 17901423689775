import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { connect } from 'react-redux';
import "./style.scss";

const Ocean = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
    }));

    useEffect(() => {

    }, []);

    return (
        <div className="ocean">
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
        </div>
    );
})

const mapStateToProps = (state) => {
    return ({
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(Ocean);