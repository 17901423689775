import ResultTest from '../screens/patients/result';
export const listData = [
    {
        key: 'ketquaxetnghiem',
        label: 'Kết quả xét nghiệm',
        content: ResultTest,
        code: 'dda01',
        icon: 'fa fa-microscope'
    }
]
