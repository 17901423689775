import React from "react";
import { connect } from 'react-redux';
import { listData } from '../../../commons/menuData';
import * as Actions from '../../../libs/actions';
import { store } from '../../../stores';
import * as UserService from '../../../services/usersService';
import './main.css';


var groupid = '';
class NewTree extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openNodes: {},
            location: '',
            lstKey: [],
            lstTabs: [],
            activeTab: '',
            permissions: '',
            permissionsarrays: [],
        }
    }


    componentWillMount() {

        groupid = localStorage.getItem('groupid') || '';
    }

    componentDidMount() {

        // UserService.getPermission().then((res) => {
        //     if (res.isSuccess === true) {
        //         let array = res.item.split(",");
        //         this.setState({ permissions: res.item, permissionsarrays: array });
        //     }
        // })
        this.setState({ permissions: "@@@"})
    }

    isOpennode(key) {
        let openNodes = this.state.openNodes
        if (openNodes[key] && (openNodes[key] === true)) {
            return true;
        }
        return false
    }

    getClassOpen(node) {
        if (node.nodes === undefined || node.nodes.length < 1) {
            return (<div></div>)
        }
        let openNodes = this.state.openNodes
        if (openNodes[node.key] !== undefined && (openNodes[node.key] === true)) {
            return (
                <i key={`fa fa-caret-down` + node.key} style={{ marginLeft: 6, fontSize: 12 }} className={`fa fa-caret-down`}></i>
            )
        } else {
            return (
                <i key={`fa fa-caret-right` + node.key} style={{ marginLeft: 6, fontSize: 12 }} className={`fa fa-caret-right`}></i>
            )
        }
    }
    CloseSideBarMobile() {
        let sideBar = document.getElementById('left-sidebar')
        let com = window.getComputedStyle(sideBar);
        let widthSideBar = com.left.replace('px', '')
        if (Number(widthSideBar) >= 0) {
            document.getElementById('sidebartoggler').click();
        }
        let sideWidth = window.getComputedStyle(sideBar).width;
        let tabHeader = document.querySelector('#tab-header-menu>.ant-tabs-nav')
        if (window.innerWidth > 768 && window.innerWidth < 993) {
            sideWidth === '220px' ? tabHeader.style.left = '220px' : tabHeader.style.left = '75px';
        }
        else {
            tabHeader.style.left = 'unset';
        }
    }
    handleToogle(node, parentKey = "") {
        let itemClick = parentKey.split('***');
        let lst = this.state.lstKey;
        const num = lst.findIndex(x => x === node.key);
        if (num < 0) {
            lst = itemClick;
        }
        else {
            lst = lst.filter(x => x !== node.key)
        }
        this.setState({ lstKey: lst }, () => {
            setTimeout(() => {
                if (node.key === "Logout") {
                    Actions.logout();
                    return;
                }
                let width = window.innerWidth;
                //let lsttab = this.state.lstTabs;
                // if ((width < 768 && lsttab.findIndex(x => x === node.key) > 0)) {
                //     this.CloseSideBarMobile();
                // }
                if (node.nodes !== undefined && node.nodes.length > 0) {
                    let toogleValue = true;
                    let openNodes = this.state.openNodes
                    if (openNodes[node.key] && (openNodes[node.key] === true)) {
                        toogleValue = false;
                    }
                    openNodes[node.key] = toogleValue;
                    this.setState({ openNodes });
                } else {
                    if (node.content) {
                        let lsttab = this.state.lstTabs;
                        let { tabs, activeTab } = this.props
                        let isAddTabs = true;
                        if (tabs === undefined) tabs = [];

                        if (activeTab === undefined) activeTab = 0;
                        for (let i = 0; i < tabs.length; i++) {
                            if (node.key === tabs[i].key) {
                                isAddTabs = false;
                                activeTab = tabs[i].key;
                                break;
                            }
                        }

                        if (!isAddTabs) {
                            this.CloseSideBarMobile();
                            this.setState({ activeTab: activeTab })
                            store.dispatch({ type: 'SET_TAB', tabs: tabs, activeTab: activeTab });
                        } else {
                            tabs.push(node);
                            activeTab = node.key;
                            lsttab.push(node.key);
                            this.setState({ lstTabs: lsttab }, () => {
                                this.CloseSideBarMobile();
                            })
                            this.setState({ activeTab: activeTab })
                            store.dispatch({ type: 'SET_TAB', tabs: tabs, activeTab: activeTab });
                        }
                        if (width < 768 && lsttab.findIndex(x => x === node.key) > 0) {
                            this.CloseSideBarMobile();
                        }
                    }
                }
            }, 100);

        })
    }

    checkActive(node) {
        let lstKey = this.state.lstKey;
        //let lstTab = this.state.lstTabs;
        //const numTab = lstTab.findIndex(x => x === node.key);
        const numKey = lstKey.findIndex(x => x === node.key);
        if (numKey < 0) {
            if (this.props.activeTab === node.key)
                return true;
            return false;
        }
        else {
            return true;
        }
    }

    renderNode(node, lvl, index, parentkey = "") {
        return (
            <li key={node.key + this.isOpennode(node.key)}>
                <a
                    className={`${(node.nodes === undefined || node.nodes.length < 1) ? '' : 'has-arrow'} waves-effect waves-dark ${this.checkActive(node) === true ? 'active font-weight-bold' : 'no-active'}`}
                    href="javascript:void(0)"
                    aria-expanded="false"
                    onClick={() => { this.handleToogle(node, `${parentkey}`) }}
                >
                    {node.icon &&
                        <i style={{ marginRight: 5 }} className={node.icon}></i>
                    }
                    {lvl === 0 ?
                        <span className="hide-menu">{node.label}</span>
                        :
                        node.label
                    }</a>
                {node.nodes !== undefined &&
                    <ul key={node.key + 'sub'} aria-expanded="false" className={`collapse ${this.checkActive(node) === true ? 'in' : ''}`}>
                        {node.nodes !== undefined && node.nodes.map((data, index) => {

                            if (this.IshasPermission(data.code, data.key)) {
                                return this.renderNode(data, lvl + 1, index, `${parentkey}***${data.key}`)
                            }
                            else {
                                return null
                            }
                        }
                        )}
                    </ul>
                }
            </li>

        )
    }
    IshasPermission(code, key) {

        if (key === 'Logout') return true;
        if (this.state.permissions.includes('@@@')) {
            return true
        }

        for (let item of this.state.permissionsarrays) {

            if (item.includes(code) || item === code) {
                return true;
            }
        }
        return false

    }
    render() {
        let { permissions } = this.state
        return (
            <React.Fragment>
                {permissions !== '' &&
                    <ul id="sidebarnav">
                        {listData.map((data, index) => {
                            if (this.IshasPermission(data.code, data.key)) {
                                return this.renderNode(data, 0, index, data.key)
                            }
                        }
                        )}
                    </ul>
                }
            </React.Fragment>
        );
    }

}
const mapStateToProps = (state) => ({
    tabs: state.global.tabs,
    activeTab: state.global.activeTab,
});
export default connect(
    mapStateToProps,
)(NewTree);