import React, { Fragment } from 'react';

function HeaderModal(props) {
    const handleCancel = () => {
        props.onClose();
    }
    return (
        <Fragment>
            <div className="mita-modal-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className='mita-text'>{props.title ? props.title : "Chưa có tiêu đề"}</div>
                {props.onClose && <h3 onClick={() => { handleCancel(); }}>
                    <i className='fas fa-times stopRowClick' />
                </h3>}
            </div>
        </Fragment>
    );
}

export default HeaderModal;