import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Button, DatePicker, Input } from 'antd';
import { connect } from 'react-redux';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import { Icons } from "../../libs/icons";
import moment from 'moment';
import history from '../../stores/history';
import { CalendarOutlined } from '@ant-design/icons';

const Header = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
    }));

    const [dateFrom, setDateFrom] = useState(sessionStorage.getItem("dateFrom") ? moment(sessionStorage.getItem("dateFrom")) : moment());
    const [dateTo, setDateTo] = useState(sessionStorage.getItem("dateTo") ? moment(sessionStorage.getItem("dateTo")) : moment());
    const [keyword, setKeyword] = useState();

    const onSearch = (key = keyword ?? "") => {
        let filterModel = {
            keyword: key,
            dateFrom: dateFrom ?? null,
            dateTo: dateTo ?? null,
            skipCount: 0,
        }
        if (dateFrom && dateTo) {
            if (moment(dateFrom) > moment(dateTo)) {
                props.showNotification && props.showNotification("Ngày bắt đầu phải nhỏ hơn ngày kết thúc");
                return;
            }
        }
        props.onSearch && props.onSearch(filterModel);
    }

    const setSession = (name, value) => {
        sessionStorage.setItem(name, value);
    }

    return (
        <div className='d-flex flex-wrap align-items-center justify-content-center mrt-1' style={{ gap: 20 }}>
            <div className='d-flex align-items-center' style={{ gap: 20 }}>
                <div className='text-center d-flex flex-row justify-content-center align-items-center mt-2'>
                    <CalendarOutlined className="mita-text mr-1" />
                    <DatePicker
                        format={"DD/MM/YYYY"}
                        suffixIcon={""}
                        className='mita-date-picker mita-text'
                        locale={viVN}
                        placeholder='Từ ngày'
                        value={dateFrom}
                        onChange={(e) => { setDateFrom(e); setSession("dateFrom", e) }}
                        allowClear={false}
                        getPopupContainer={(p) => p.parentNode}
                        disabledDate={(date) => { return date && date > dateTo; }}
                    >
                    </DatePicker>
                </div>
                <div className='text-center d-flex flex-row justify-content-center align-items-center mt-2'>
                    <CalendarOutlined className="mita-text mr-1" />
                    <DatePicker
                        format={"DD/MM/YYYY"}
                        suffixIcon={""}
                        className='mita-date-picker mita-text'
                        locale={viVN}
                        placeholder='Đến ngày'
                        value={dateTo}
                        onChange={(e) => { setDateTo(e); setSession("dateTo", e) }}
                        allowClear={false}
                        getPopupContainer={(p) => p.parentNode}
                        disabledDate={(date) => { return date && date < dateFrom; }}
                    >
                    </DatePicker>
                </div>
            </div>
            <div className='text-center d-flex align-items-center text-center' style={{ gap: 20 }}>
                <Input className='mita-input mita-input-check mita-text mt-2'
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                    placeholder='Nhập tên hoặc số điện thoại của bệnh nhân'
                    onKeyDown={(e) => { if (e.key == "Enter") onSearch(e.target.value) }} />
                <Button className='mita-btn-round-main mt-2' onClick={() => onSearch()}>Tìm kiếm</Button>
            </div>
        </div>
    );
})

const mapStateToProps = (state) => {
    return ({
        global: state.global,
        router: state.router,
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(Header);