import * as ApiCaller from "../libs/httpRequests";

const prefix = 'exam-result';

export function getResultByTrackingCode(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/getby-trackingcode`, data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
