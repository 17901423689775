import { Tooltip, Modal } from 'antd';
import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import ErrorMessageModal from '../ErrorMessageModal';
import Loading from '../loading';
import NavLeftSlideBar from '../navLeftSlideBar';
import Printer from '../printer';
import ChangePassword from '../changePassword';
import * as Actions from '../../libs/actions';
import './main.css';
import Heading from '../heading';

let clockTask = null
class Layout extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            openNav: true,
            visible: false,
            setVisible: false,
            print: false,
            listIndex: [],

            ishasExpiredItem: false,
            mabd: 0,
            tenthuoc: '',
            maKho: 519,
            ngay: moment(),
            totalRecords: 0
        }
    }
    componentWillUnmount() {
        if (clockTask) {
            clearInterval(clockTask);
        }
    }
    afterPrint() {
        setTimeout(() => {
            Actions.closePrintDialog();
        }, 300);
    }
    checkExpiredMedicine() {

    }
    componentDidMount() {

        if (window.onafterprint === null || window.onafterprint === undefined) {
            window.onafterprint = this.afterPrint.bind(this);
        }
        setInterval(() => {
            this.setState({ date: moment() })
        }, 1000);
        this.checkExpiredMedicine();
    }

    showDrawer() {
        this.setState({ setVisible: true });
    };
    onClose() {
        this.setState({ setVisible: false });
    };


    handleToggle() {
        this.setState({ openNav: !this.state.openNav })
    }

    onDocumentLoadSuccess({ numPages }) {
        this.setState({ numPages })
    }
    getlock() {
        return this.state.date ? this.state.date.format('DD-MM-YYYY  HH:mm:ss') : ''
    }
    CloseChangePass() {
        this.setState({ setVisible: false })
    }
    render() {
        return (
            <React.Fragment>
                {this.props.file !== undefined && this.props.file !== null &&
                    <Printer file={this.props.file} printNewTab={this.props.printNewTab}></Printer>
                }
                <div className={'notprint'} >
                    <Modal visible={this.state.setVisible}>
                        <ChangePassword open={this.state.setVisible} onClose={this.CloseChangePass.bind(this)}></ChangePassword>
                    </Modal>
                    <Loading show={this.props.loadingModal} />
                    <ErrorMessageModal />
                    <Heading />
                    <div className="page-wrapper">
                        <div className="container-layout" style={{ height: 'calc(100vh - 90px)' }}>{/* HEADER */}
                            <div className="wrapper-layout-mita" >
                                <div className="right-wrapper-mita" style={{ maxWidth: '100%' }}>
                                    <div className="content-mita">
                                        <div
                                            className="wrapperContent"
                                            style={{
                                                height: '100%', width: '100%', backgroundColor: 'rgba(254,254,254,0.3)', overflowX: 'hidden'
                                            }}>

                                            {this.props.children}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal maskClosable={false}
                    width={1500}
                    visible={this.state.expiredModal}
                    onCancel={() => { this.setState({ expiredModal: false }) }}
                    cancelButtonProps={{ hidden: false, disabled: false }}
                    okButtonProps={{ disabled: true, hidden: true }}>
                </Modal>
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    return ({
        loadingModal: state.modal.loadingModal,
        print: state.global.print,
        printNewTab: state.global.printNewTab,
        file: state.global.file,
        name: state.global.name,
        title: state.global.title,
        tabs: state.global.tabs,
        userID: state.global.userID
    })
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
    }, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout))