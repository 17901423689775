import * as ApiCaller from "../libs/httpRequests";

const prefix = 'thirdparty';

export function LichSuXetNghiem(from, to, page = 1, size = 10) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/getSamples?from=${from}&to=${to}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function KetQuaXetNghiem(maql, sid) {
    if (sid === undefined)
        sid = "";
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/getResult?profileCode=${maql}&sid=${sid}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ThongTinBenhNhan() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/patientInfo`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
